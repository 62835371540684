const { url, routes } = window;

export default class TrackingCirculation {

    get isDebug() {
        return _(window.ga).isEmpty();
    }

    get debugInstance() {
        return {
            push: ({ event, content, isNotification }) => {
                console.log(`ga:log("${event}"):`, { event, content, isNotification }); // eslint-disable-line no-console
            }
        };
    }

    constructor(bus) {
        this.bus = bus;

        this._dataLayer = window.dataLayer || this.debugInstance;
        this._websiteId = window.OverDrive.tenant;

        this.bus
            .on('title:borrow:success',         (params) => this.pushEvent(params, 'titleBorrow'))
            .on('title:hold:success',           (params) => this.pushEvent(params, 'titleHold'))
            .on('title:hold-cancel:success',    (params) => this.pushEvent(params, 'titleHoldCancel'))
            .on('title:hold-lapsed:dismiss',    (params) => this.pushEvent(params, 'titleHoldLapsedDismiss'))
            .on('title:wishlist:success',       (params) => this.pushEvent(params, 'titleWishlist'))
            .on('title:recommend:success',      (params) => this.pushEvent(params, 'titleRecommend'))
            .on('title:history:add:success',    (params) => this.pushEvent(params, 'titleHistory'))
            .on('title:download',               (params) => this.pushEvent(params, 'titleDownload'))
            .on('title:suspend',                (params) => this.pushEvent(params, 'titleSuspend'))
            .on('title:suspend:cancelled',      (params) => this.pushEvent(params, 'titleSuspendCancelled'))
            .on('title:suspend:dismiss',        (params) => this.pushEvent(params, 'titleSuspendDismiss'))
            .on('title:recommended',            (params) => this.pushEvent(params, 'titleRecommended'))
        ;
    }

    pushEvent({ titleModel, context }, event) {
        let content = _.extend({},
            this.getTitleProps(titleModel),
            window.featureManager.isEnabled('recommend-strategy-ga') ? this.getStrategy(context) : {},
            this.getCollectionProps(context ? context.collectionDefinition : {}),
            this.getCampaignProps(titleModel.context ? titleModel.context.campaign : {}),
            (context && context.formatId) ?  { formatId: context.formatId } : {},
            window.featureManager.isEnabled("holdRedelivery") ? this.getSuspendTitleProps(context, titleModel, event) : {},
        );

        // Renew and request again aren't distinct events as far as lightning's api are concerned, but we
        // want to report them as distinct events.
        if (event === 'titleBorrow' && context.isRenewal){
            event = 'titleRenew';
        } else if(event === 'titleHold' && context.isRequestAgain){
            event = 'titleRequestAgain';
        }

        let isNotification;
        (context) ? isNotification = context.isNotification : isNotification = false;

        this._dataLayer.push({ event, content, isNotification });
    }

    getTitleProps(titleModel) {
        let t = titleModel.attributes;

        return {
            mediaID     : t.id,
            titleName   : t.title,
            author      : t.firstCreatorName,
            formatClass : t.type && t.type.id || 'unknown'
        };
    }

    getStrategy(context)
    {
        if(context && context.strategy) {return {strategy: context.strategy};}
        return {};        
    }

    getCollectionProps(collectionDefinition) {
        if (_(collectionDefinition).isEmpty()) { return { }; }

        let c = collectionDefinition;
        return {
            listID      : c.attributes.id,
            listName    : c.attributes.name,
            listUrl     : url(routes.collectionById, { collectionId: c.attributes.id }),
            // LHTNG-2199 - This is currently the only place in the code where these are defined as strings,
            // additionnally we have to be consistent with case sensitivity otherwise we will messup Google Analytics
            listType    : c.isCurated ? 'Curated' : 'Generated',
            isLuckyDay  : c.attributes.showOnlyLuckyDayAvailable
        };
    }

    getCampaignProps(campaign) {
        if (_(campaign).isEmpty()) { return { }; }

        return {
            campaignId : campaign.id,
            campaignName: campaign.name
        };
    }

    getSuspendTitleProps(context, titleModel, event) {
        if(event === "titleSuspend" && !_(context).isEmpty() && context.numOfDays){
            return {
                suspendDays : context.numOfDays,
                isRedelivered : context.isRedelivery,
                isSuspended: context.isSuspended,
                autoRedeliveriesCount : titleModel.get("redeliveriesAutomatedCount"),
                requestedRedeliveryCount : titleModel.get("redeliveriesRequestedCount")
            };
        }else if(event === "titleSuspendCancelled") {
            return {
                autoRedeliveriesCount : titleModel.get("redeliveriesAutomatedCount"),
                requestedRedeliveryCount : titleModel.get("redeliveriesRequestedCount")
            };
        }else{
            return {};
        }
    }
}
