import { Modal }                        from '../common/modal';
import SessionExpiredModalTemplate    from './SessionExpiredModal.html';
import buildSupportUrl from '../../../../lib/supportUrl';
const { text } = window;

export default class SessionExpiredModal extends Modal {
    constructor(forwardUrl) {
        let supportUrl = window.routes.supportMembers;
        let signInUrl = `${window.routes.accountInvalidCard}${forwardUrl ? `?forward=${encodeURIComponent(forwardUrl)}` : ''}`;

        let followTheseStepsLink = `<a target="_blank" href="${supportUrl }">${text('pleaseContactSupport')}</a>`;
        let stillTroubleText = text('sessionExpiredModal.stillHavingTrouble', {followTheseSteps: followTheseStepsLink });
        const modalContents = {
            classes: ['SessionExpiredModal'],
            title: text('reauthenticateModal.heading'),
            content: SessionExpiredModalTemplate({signInUrl, stillTroubleText})
        };

        super(modalContents);
        this.$el.find('.cancel').on('click', () => {
            this.close();
        });
    }
}
