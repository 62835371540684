import RtlModal         from '../../components/media/views/RtlModal';
import RtlLimitModal    from '../../components/media/views/RtlLimitModal';
import VisitorActionDisallowedModal from '../../components/visitor_action_disallowed_modal/VisitorActionDisallowedModal';

export default class Recommend {
    constructor(bus, card, patronSettings) {
        this.bus = bus;
        this.card = card;
        this.patronSettings = patronSettings;

        this.bus.on('title:recommend:post', (data) => this._recommendAjax(data));
    }

    recommendTitle(titleModel, context) {
        let rtlStats = this.card.attributes.recommendationStatistics;

        if (window.featureManager.isEnabled('clear-0recommend-cache')) {
            if (rtlStats && rtlStats.recommendationsRemaining === 0) { this.card.cacheReset(); }
        }

        if (!window.featureManager.isEnabled('rtl-sunset')) {
            this.card._fetch.then(() => {
                if (window.OverDrive.isVisitor && !window.featureManager.isEnabled('recommend-to-library')){
                    new VisitorActionDisallowedModal('recommend');
                } else if (window.featureManager.isEnabled('recommend-to-library')) {
                    rtlStats = this.card.attributes.recommendationStatistics;

                    if (rtlStats.recommendationsRemaining > 0) {
                        if (this.card.preferredHoldEmail.length) {
                            this.bus.trigger('title:recommend:post', {
                                titleModel : titleModel,
                                email      : this.card.preferredHoldEmail,
                                context    : this.context
                            });
                        }
                        else {
                            // LHTNG-2868 - Only show the RTL modal now if we need to collect the user's email for placing a hold on the recommended title
                            void (new RtlModal(this.bus, titleModel, context));
                        }
                    }
                    else {
                        void (new RtlLimitModal(rtlStats));
                    }
                }
            });
        }
    }

    _recommendAjax({ titleModel, email, context }) {
        this.bus.trigger('title:recommend:start', { titleModel, email, context });

        titleModel.set('titleState', 'waiting');

        let autoCheckoutSetting = this.patronSettings['auto-checkout-setting'];

        return $.ajax({
            url: window.url(window.routes.patronRecommendations),
            type: 'POST',
            data: {
                id                : titleModel.id,
                addHold           : true, // LHTNG-2868 - Always add hold when recommending
                notifyIfPurchased : true,
                email             : email,
                autoCheckout      : autoCheckoutSetting && autoCheckoutSetting === 1
            }
        })
        .done(() => this.bus.trigger('title:recommend:success', { titleModel, email, context }))
        .fail((jqXHR) => {

            // The response is sometimes json and sometimes not.  Fun.
            let response;
            try {
                response = JSON.parse(jqXHR.responseText);
            } catch (ex){
                response = jqXHR.responseText;
            }
            titleModel.set('titleState', 'recommend');
            this.bus.trigger('title:recommend:fail', { titleModel, email, context, response });

        })
        .complete(() => { this.bus.trigger('title:recommend:end', { titleModel, email, context }); });
    }
}
