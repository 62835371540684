import BaseModel from '../models/base';

const PatronMediaItemsModel = BaseModel.extend({
    url() {
        return window.url(window.routes.mediaInfo);
    },

    initialize({titles}) {
        this.mediaItems = titles || window.OverDrive.titleCollection || {};
        this.itemsOnPage = _.map(_.pluck(this.mediaItems, 'id'), Number);
    },

    initialFetch() {
        return this.mediaItems.length
            ? this.fetch({
                data: JSON.stringify({titleIds: this.itemsOnPage}),
                type: 'POST', contentType: 'application/json',
                headers: {'csrf-token': $('#csrf').val() || OverDrive._csrf}})
            : Promise.resolve({});
    }
});

export default PatronMediaItemsModel;
