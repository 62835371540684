import { Modal }                    from '../common/modal';
import PatronTitleLendingPeriods    from './PatronTitleLendingPeriods';
import Cover                        from '../media/Cover';
import RadioButton                  from '../../components/common/RadioButton';

import LendingPeriodModalTemplate   from './LendingPeriodModal.html';

export default class LendingPeriodModal extends Backbone.View {
    constructor({bus, action, onError, isRenewal = false }) {
        super(...arguments);

        this.bus = bus;
        this.action = action || (() => {});
        this.onError = onError || (() => {});
        this.isRenewal = isRenewal;

        this.cover = new Cover({model: this.model});
        // Expects a Title object as its model
        this.patronTitleLendingPeriods = new PatronTitleLendingPeriods({mediaId: this.model.get('id')});
        this.patronTitleLendingPeriods.fetchData()
            .then(() => {
                const lendingPeriods = this.patronTitleLendingPeriods.get('lendingPeriods');
                this.model.set('titleState', this.isRenewal ? 'renew' : 'borrow');
                this._openModal(lendingPeriods);
            })
            .catch((error) => {
                this.onError(error);
            });
    }

    _openModal(lendingPeriods) {
        let maybeCheckHoldStatus = new Promise((resolve) => {
            if (this.model.get('isOnHold')) {
                $.ajax({
                    url: window.url(window.routes.getSingleHold, {mediaId: this.model.get('id')}),
                    type: 'GET'
                }).done((response) => {
                    this.model.set('isReleasedHold', response.isAvailable);
                }).always(() => {
                    resolve();
                });
            }
            else {
                resolve();
            }
        });

        maybeCheckHoldStatus.then(() => {
            let isLuckyDayRestricted = this.model.isLuckyDayCheckout
                                    && !!window.OverDrive.LuckyDayLendingPeriodsByFormat
                                    && !!window.OverDrive.LuckyDayLendingPeriodsByFormat[this.model.get('type').id]
                                    && !this.isRenewal;

            let isLuckyDayHoldable = isLuckyDayRestricted
                                    && this.model.canPlaceHold
                                    && !this.model.allCopiesAreLuckyDay;

            let luckyDayLendingPeriod;
            if (isLuckyDayRestricted) {
                luckyDayLendingPeriod = window.OverDrive.LuckyDayLendingPeriodsByFormat[this.model.get('type').id];
            }

            let showRemoveHoldOption = this.model.isLuckyDayCheckout
                                            && this.model.get('isOnHold')
                                            && window.featureManager.isEnabled('allowRemoveHoldsOnCheckout')
                                            && !this.model.get('isReleasedHold')
                                            && isLuckyDayRestricted;

            let removeHold =  this.model.isLuckyDayCheckout
                                    && !showRemoveHoldOption
                                    && this.model.get('isOnHold');


            let modalTitle;
            if (this.isRenewal){
                modalTitle = window.html('renewal.renewHeading');
            } else if (isLuckyDayRestricted){
                modalTitle = window.html('luckyDay.xDayLoan', {days: luckyDayLendingPeriod});
            } else {
                modalTitle = window.html('borrow');
            }
            const modalContents = {
                classes: ['LendingPeriodModal', 'small'],
                title: modalTitle,
                hiddenTitle: !isLuckyDayRestricted,
                content: LendingPeriodModalTemplate({
                    title: this.model,
                    cover: this.cover,
                    lendingPeriods: isLuckyDayRestricted ? [] : lendingPeriods,
                    isRenewal: this.isRenewal,
                    showRemoveHoldOption,
                    removingHold: removeHold,
                    isLuckyDayRestricted,
                    isLuckyDayHoldable,
                    luckyDayLendingPeriod
                })
            };
            this.modal = new Modal(modalContents);
            const $modalEl = this.modal.$el;

            if (showRemoveHoldOption) {
                new RadioButton({
                    value: true,
                    checked: true,
                    el: '.removeHold',
                    className: 'removeHoldOption',
                    name: 'removeHoldOption',
                    label: window.html('accountHolds.removeHoldLabel'),
                    rid: 0
                }).render();

                new RadioButton({
                    value: false,
                    el: '.stayOnHold',
                    className: 'removeHoldOption',
                    name: 'removeHoldOption',
                    label: window.html('accountHolds.stayOnHold'),
                    rid: 0
                }).render();
            }

            $modalEl.on('click', '.cancel-button', () => { this.modal.close(); });

            $modalEl.on('click', '.borrow-button', () => {

                let selectedLendingPeriod;
                if (isLuckyDayRestricted){
                    selectedLendingPeriod = {
                        units: 'days',
                        isPreferredLendingPeriod: false,
                        lendingPeriod: luckyDayLendingPeriod
                    };
                }
                else {
                    selectedLendingPeriod = lendingPeriods.length > 1
                    ? lendingPeriods.get($modalEl.find('.lending-period-select').val()).toJSON()
                    : lendingPeriods.first().toJSON();
                }

                this.model.set('lendingPeriod', selectedLendingPeriod);

                $modalEl.find('.borrow-button').addClass('waiting').append('<div class="spinner"></div>');

                if (!window.featureManager.isEnabled("removeExtraHoldCancel") && removeHold) {
                    this.bus.on('title:borrow:success', () => {
                        this.bus.trigger('title:hold-cancel', this.model);
                    });
                }

                this.action(this.modal);
            });

            $modalEl.on('click', '.hold-button', () => {
                this.model.trigger('title:action', { action: 'hold', model: this.model });
                $modalEl.find('.hold-button').addClass('waiting').append('<div class="spinner"></div>');
                this.bus.on('title:hold:end', () => {
                    this.modal.close();
                });
            });

            $modalEl.on('change', '.removeHoldOption', (event) => {
                removeHold = event.target.value === 'true';
            });
        });
    }
}
