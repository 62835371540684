import TitleCard            from '../TitleCard';
import TitleCollection      from '../models/TitleCollection';
import TitleCollectionView  from '../TitleCollectionView';
import HoldInformation      from '../../holdInformation/views/HoldInformation';
import ContainerTemplate    from '../templates/HoldSuccessTemplate.html';
import { Modal }            from '../../common/modal';

const { featureManager, text } = window;
const fs = require('fs');
const path = require('path');
const containerTemplateHtml = fs.readFileSync(path.join(__dirname, '../templates/HoldSuccess.html'), 'utf8');
import relatedTitlesTemplate from '../templates/HoldSuccessRelatedTitles.html';
import MacmillanMessage from '../../../../../lib/utility/macmillanMessage';

export default class HoldSuccessModal extends Backbone.View {

    topLevelTemplate = featureManager.isEnabled('fixHtmlTagsInCopy') ? ContainerTemplate : (_.template(containerTemplateHtml));

    constructor({ bus, titleModel, email, autoCheckoutEnabled }) {
        super();
        this.bus = bus;
        this.titleModel = titleModel;
        this.email = email;
        this.autoCheckoutEnabled = autoCheckoutEnabled;
        this.collection = new TitleCollection();
        this.collection.url = `${window.url(window.routes.rest.media.availableRelated, {id: titleModel.id})}?max=4`;
        this.collection.parse = (response) => {
            let collectionTitles = response.titles;
            if (window.featureManager.isEnabled('only-show-owned-content') && response.titles) {
                collectionTitles = response.titles.filter((x) => x.isOwned === true);
            }
            return _.values(collectionTitles);
        };
        this.ownedCopies = this.titleModel.get('ownedCopies') || 0;
        this.macmillanMessage = MacmillanMessage(this.titleModel);

        this.renderContainer();
        this.$titleContainer = this.$('.relatedTitlesContainer');

        const waypoint = new window.Waypoint.Inview({
            element: this.$titleContainer,
            enter: () => {
                waypoint.destroy();
                this.fetchRelatedTitles();
            }
        });

        let modalContents = {
            autoShow: false,
            title: html('genericSuccess'),
            classes: ['large, HoldSuccessModal'],
            content: this.$el
        };

        this.modal = new Modal(modalContents);
        this.modal.show();

    }

    fetchRelatedTitles() {
        this.collection.fetch()
            .then((res) => {
                if (_(res.titles).isEmpty()) {
                    this.$titleContainer.empty();
                    return;
                }

                if (window.featureManager.isEnabled('recommend-strategy-ga'))
                {
                    this.bus.trigger('title:recommended', {
                        titleModel : this.titleModel,
                        context    : {strategy: res.strategy}
                    });
                }

                this.renderRelatedTitles();
            })
            .fail(() => {
                this.$titleContainer.empty();
            });
    }

    getHoldInfoHtml(){
        if (featureManager.isEnabled('LHTNG-2863')) {
            let note = null;
            let isExpired = featureManager.isEnabled('dont-mark-su-as-expired') ? this.titleModel.isExpired
                : this.titleModel.get('isOwned') && !this.titleModel.get('ownedCopies');

            if (isExpired) {
                note =  `<span>${text('holdsRatioHelpModal.noCopiesOnPlaceHold')}<a class="manageHoldsLink" target="_blank" href="/account/holds">${text('holdSuccessModal.manageHolds')}</a></span>`;
            } else if(this.titleModel.get('isFastlane')){
                note = text('expectedWaitTime.advantageCaveat');
            } else if (window.OverDrive.isVisitor){
                note = text('expectedWaitTime.rlaCaveat');
            }

            return `<p class="holdPosition">
                        <span class="textLabel">${text('expectedWaitTime.holdPositionLabel')}</span>
                        <span>${text('accountHolds.holdListPosition', {holdListPosition: this.titleModel.get('holdListPosition') , ownedCopies: this.titleModel.get('ownedCopies') })}</span>
                    </p>
                    ${note ? `<p class="note"><span class="textLabel">${text('note')}</span><span>${note}</span></p>` : ''}`;
        }
        else {
            let info = new HoldInformation({model: this.titleModel, type: 'successPosition' }).render();
            return info.$el.html();
        }
    }

    renderRelatedTitles() {

        this.$titleContainer.empty();
        this.$titleContainer.append(relatedTitlesTemplate({titleModel: this.titleModel.attributes}));

        this.titleCollectionView = new TitleCollectionView({
            el: this.$titleContainer.find('.relatedTitles'),
            bus: this.bus,
            View: TitleCard,
            attributes: {class: 'titleContainer small-block-grid-2 medium-block-grid-4 large-block-grid-4'},
            collection: this.collection,
            coverWidths: {mobile: 150, tablet: 185, desktop: 220}
        });
    }

    renderContainer() {
        let searchByCreatorUrl = window.url(window.routes.searchByType,
            { type: 'creatorId', query: this.titleModel.get('firstCreatorId'), sortBy: 'newlyadded' });

        this.$el.append(this.topLevelTemplate({
            successMessageTextKey: this.autoCheckoutEnabled && !featureManager.isEnabled('holdRedelivery') ? 'toasts.holdSuccessAutoCheckoutHtml' : 'toasts.holdSuccessHtml',
            manageHoldsUrl: window.url(window.routes.accountHolds),
            email: this.email,
            titleModel: this.titleModel.attributes,
            creatorSearchUrl:  searchByCreatorUrl,
            holdsInfo: this.getHoldInfoHtml(),
            ownedCopies: this.ownedCopies,
            macmillanMessage: this.macmillanMessage
        }));
    }
}
