/**
 * Holds functions
 * @type {{holdPlaced: holds.holdPlaced, revealHoldModal: holds.revealHoldModal}}
 */
window.holds = {
    holdPlaced: function (elements) {
        window.ajax.placeHold(
            elements.attr('data-media-id'),
            $('.patron-hold-email-dynamic').val(),
            elements
        );
    },

    /**
     * Reveal the place a hold modal
     */
    revealHoldModal: function (elem) {
        if (!elem.hasClass('disabled')) {
            var title,
                typeId,
                typeName,
                artwork,
                prereleaseDate,
                isPrerelease,
                mediaId;

            title = elem.attr('data-title');
            typeId = elem.attr('data-type');
            typeName = elem.attr('data-type-name');
            artwork = elem.attr('data-artwork');
            prereleaseDate = elem.attr('data-prerelease-date');
            isPrerelease = elem.attr('data-prerelease-date') != '';
            mediaId = elem.attr('data-media-id');

            var modal = $('#hold-dynamic-modal');
            $('[data-id="title"]').html(title);
            $('[data-id="format-icon"]')
                .removeClass()
                .addClass('icon-' + typeId);
            $('[data-id="format-name"]').html(typeName);
            $('[data-id="cover-image"]').attr('src', artwork);

            var labelCopy = window.html('dynamicHoldModal.placeHoldDesc', {
                libraryName: window.OverDrive.libraryName,
            });
            if (window.OverDrive.mediaItems[mediaId].ownedCopies === 0) {
                labelCopy = window.html('holdsRatioHelpModal.noCopiesText');
            }
            $('.js-hold-modal-label').html(labelCopy);

            if (prereleaseDate != undefined && isPrerelease) {
                $('[data-id="pre-release-label"]')
                    .text(
                        window.text('prereleaseLabel', {
                            prereleaseDate: prereleaseDate,
                        })
                    )
                    .removeClass('hideMe');
            } else {
                $('[data-id="pre-release-label"]').text('').addClass('hideMe');
            }

            $('form#hold-dynamic-form').attr('data-media-id', mediaId);

            if (
                elem.attr('data-publisher') >= '98146' &&
                elem.attr('data-publisher') <= '98155' &&
                Cookies.get('disney-eula-setting') != '1'
            ) {
                // Disney publisher ids are 98146 - 98155
                $('#disney-eula-modal').foundation('reveal', 'open');
            } else {
                modal.foundation('reveal', 'open');
            }
        }
    },
};

/**
 *  Functions for managing action button states
 */
window.loading = {
    spinners: {},

    animateIcon: function (elem, animation) {
        elem.removeClass('animated bounceIn bounceOut pulse infinite').addClass(
            'animated ' + animation
        );
    },

    changeButtonState: function (elem, action, onStatusReq) {
        elem = elem.attr('data-action')
            ? elem
            : elem.closest(
                  '[data-action]:not([data-action="main-navigation"])'
              );
        var inner = '';
        var type = elem.data('type');
        var mediaId = elem.data('media-id');
        var formats = elem.data('formats');
        var buttonText = '';
        var inApp = Cookies.get('omcbrowse') != undefined;
        var formatId = findFormatId(formats);
        var isChromebook = window.navigator.userAgent.match(/CrOS/)
            ? true
            : false;
        var overdriveFormat = '';

        var isAndroid41AndUp =
            bowser.android && parseInt(bowser.osversion, 10) >= 4.1
                ? true
                : false;
        var isAndroidStock =
            window.navigator.userAgent.match(/Version\/\d\.\d/) &&
            bowser.android
                ? true
                : false;
        var isDolphin = window.navigator.userAgent.match(/Dolfin/)
            ? true
            : false;
        var isNook =
            window.navigator.userAgent.match(/(BNTV|BNRV)/g) && !bowser.chrome
                ? true
                : false;
        var isSilk = /\bSilk\/([0-9._-]+)\b/.exec(navigator.userAgent)
            ? true
            : false;
        var isListenIncompatible =
            (!isAndroid41AndUp && (isAndroidStock || isDolphin || isSilk)) ||
            isNook
                ? true
                : false;

        var removeClassList =
            'secondary primary place-a-hold radius radius-left lending-options';

        var banner = $(
            'div.title-header-bar[data-media-id="' +
                elem.attr('data-media-id') +
                '"] a'
        );

        if (formats) {
            for (var i = 0; i < formats.length; i++) {
                if (
                    formats[i]['id'] == 'video-streaming' ||
                    formats[i]['id'] == 'ebook-overdrive' ||
                    formats[i]['id'] == 'audiobook-overdrive'
                ) {
                    overdriveFormat = formats[i]['id'];
                }
            }
        }

        switch (action) {
            case 'borrow':
                //User has not yet borrowed a title Show borrow button
                var lendingPeriod = $('input#lending-period-' + mediaId).val();
                if (type == 'periodicals') {
                    inner = window.html('day');
                    elem.addClass('secondary radius').removeClass(
                        'lending-options radius-left'
                    );

                    //$('button.lending-period-dropdown').hide();

                    if (Cookies.get('nook-eula-setting') == '1') {
                        elem.removeAttr('data-reveal-id');
                    }
                } else if (lendingPeriod > 0) {
                    inner =
                        '<span class="borrow-main-text u-allCaps">' +
                        window.html('borrow') +
                        '</span><br/><span class="borrow-sub-text u-allCaps">' +
                        window.html('forXDays', {
                            lendingPeriod: lendingPeriod,
                        }) +
                        '</span>';
                } else {
                    inner = '<span>' + window.html('borrow') + '</span>';
                    elem.css('line-height', '21px'); // Align text with other buttons
                }
                elem.removeClass(removeClassList)
                    .addClass(
                        'secondary radius-left lending-options js-statusUpdated'
                    )
                    .attr('href', 'javascript:void(0)');

                $('.lending-period-dropdown')
                    .addClass('secondary')
                    .removeClass('disabled');
                break;

            case 'borrow-simple':
                //User has not yet borrowed a title Show borrow button
                if (type == 'periodicals') {
                    inner = '<span>' + window.html('sendToNook') + '</span>';
                    elem.addClass('secondary radius').removeClass(
                        'lending-options radius-left'
                    );

                    //$('button.lending-period-dropdown').hide();

                    if (Cookies.get('nook-eula-setting') == '1') {
                        elem.removeAttr('data-reveal-id');
                    }
                } else {
                    inner =
                        '<span class="borrow-main-text u-allCaps">' +
                        window.html('borrow') +
                        '</span>';
                }
                elem.removeClass(removeClassList)
                    .addClass('secondary radius js-statusUpdated')
                    .attr('href', 'javascript:void(0)');

                elem.removeClass('place-a-hold');
                break;

            case 'hold':
                //User has not yet placed this title on hold show hold button
                inner = '<span>' + window.html('placeAHold') + '</span>';
                elem.removeClass(removeClassList)
                    .addClass('place-a-hold radius js-statusUpdated')
                    .attr('href', 'javascript:void(0)');
                break;

            case 'borrowed':
                var isios6Incompatible =
                    bowser.ios && bowser.osversion[0] === '6';

                //User has already borrowed this title. Show GO TO LOANS

                // This is temporary to disable the add to app copy. This will be refactored out soon..
                var showAddToApp = false;

                var plainText = window.html('goToLoans');
                buttonText = '<span>' + plainText + '</span>';

                elem.attr('href', window.url(window.routes.accountLoans))
                    .attr('tabindex', '0')
                    .html(window.html('goToLoans'))
                    .addClass('u-allCaps');

                inner = buttonText
                    ? buttonText
                    : '<span>' + window.html('goToLoans') + '</span>';
                elem.addClass('secondary radius').removeClass(
                    'lending-options radius-left'
                );
                elem.removeClass('place-a-hold');

                if (elem.attr('data-type') == 'periodicals') {
                    elem.removeAttr('data-reveal-id');
                }

                elem.attr('data-action', 'borrowed')
                    .removeClass(removeClassList)
                    .addClass('secondary radius js-statusUpdated');
                if (!elem.parent().hasClass('list-view')) {
                    elem.addClass('wider-button');
                    if (plainText.length > 21) {
                        elem.addClass('decreased-padding');
                    }
                } else {
                    if (plainText.length > 22) {
                        elem.addClass('decreased-padding-listview-small');
                    }

                    if (plainText.length > 19) {
                        elem.addClass('decreased-padding-listview');
                    }
                }

                banner.text(window.html('availabilityBorrowed'));
                banner
                    .closest('.title-header-bar')
                    .addClass('available-title secondary-background')
                    .removeClass('unavailable-title');

                break;

            case 'held':
                //User has already placed this title on hold. Show GO TO HOLDS
                inner = '<span>' + window.html('goToHolds') + '</span>';

                elem.attr(
                    'href',
                    window.url(window.routes.accountHolds)
                ).removeAttr('onclick');
                elem.removeClass(removeClassList).addClass('primary radius');
                if (!elem.parent().hasClass('list-view')) {
                    elem.addClass('wider-button');
                    // Account for Tamil
                    if (inner.length > 30) {
                        elem.addClass('increased-height');
                    }

                    if (inner.length > 24) {
                        elem.addClass('decreased-padding');
                    }
                } else {
                    // Account for Tamil
                    if (inner.length > 30) {
                        elem.addClass('increased-height-listview');
                    }

                    if (inner.length > 25) {
                        elem.addClass('decreased-padding-listview-small');
                    }

                    if (inner.length > 22) {
                        elem.addClass('decreased-padding-listview');
                    }
                }
                elem.attr('data-action', 'held').addClass('.js-statusUpdated');
                banner.text(window.html('onHold'));
                banner
                    .closest('.title-header-bar')
                    .removeClass('available-title secondary-background')
                    .addClass('unavailable-title')
                    .addClass('u-allCaps');
                // Account for Tamil
                if (banner.text().length > 16) {
                    elem.parent()
                        .siblings('.title-description')
                        .addClass('is-top-padded');
                }
                break;

            case 'wishlist-add':
                inner =
                    '<span class="u-allCaps">' +
                    window.html('addToWishList') +
                    '</span>';
                elem.addClass('add js-statusUpdated')
                    .removeClass('remove')
                    .attr('data-action', 'wishlist-add');
                elem.closest('.js-wishlist-button')
                    .addClass('is-add')
                    .removeClass('is-remove');
                elem.removeClass('place-a-hold');
                break;

            case 'wishlist-remove':
                inner =
                    '<span class="u-allCaps">' +
                    window.html('removeFromWishList') +
                    '</span>';
                elem.addClass('remove js-statusUpdated')
                    .removeClass('add')
                    .attr('data-action', 'wishlist-remove');
                elem.closest('.js-wishlist-button')
                    .addClass('is-remove')
                    .removeClass('is-add');
                elem.removeClass('place-a-hold');

                elem.attr('data-action', 'wishlist-remove');
                break;

            case 'special':
                inner =
                    '<span class="u-allCaps">' +
                    window.html('removeFromWishList') +
                    '</span>';
                elem.addClass('remove js-statusUpdated')
                    .removeClass('add')
                    .attr('data-action', 'wishlist-remove');
                elem.removeClass('place-a-hold');
                break;

            case null:
            case false:
                elem.children('div').remove();
                inner = elem.html();
                action = 'default';
                break;

            default:
                elem.children('div').remove();
                inner = elem.html();
                action = 'default';
                break;
        }

        elem.attr('aria-busy', 'false')
            .removeClass('disabled')
            .removeAttr('disabled');
        elem.next('button.lending-period-dropdown').removeClass('disabled');

        if (action != 'default') {
            elem.attr('data-action', action);
        }

        if (action != 'borrow' && elem.attr('data-type') != 'periodicals') {
            elem.addClass('radius')
                .removeClass('lending-options')
                .css('position', 'relative');
            elem.next('button.lending-period-dropdown').hide();
        }

        elem.html(inner).addClass('u-allCaps');

        processInstantForwardActions(
            window.location.pathname +
                (window.location.search ? window.location.search : '')
        );
    },

    updateCoverAlt: function (mediaId, text) {
        var coverImage = $('.title-header-bar[data-media-id="' + mediaId + '"]')
            .next('a')
            .children('img');
        if (coverImage.length) {
            var re = new RegExp(
                '(' +
                    window.html('availabilityBorrowed') +
                    ')|(' +
                    window.html('held') +
                    ')|(' +
                    window.html('onHold') +
                    ')|(' +
                    window.html('availabilityAvailable') +
                    ')|(' +
                    window.html('availabilityWaitList') +
                    ')|(' +
                    window.html('availabilityComingSoon') +
                    ')',
                'gi'
            );
            coverImage.attr('alt', coverImage.attr('alt').replace(re, text));
        }
    },

    updateRecommendationState: function (mediaId) {
        var $recommendButton = $(
            '.js-recommend[data-media-id="' + mediaId + '"]'
        );
        $('div.title-header-bar[data-media-id="' + mediaId + '"]')
            .children('a, span')
            .text(window.text('recommended'))
            .addClass('u-allCaps');
        $recommendButton
            .html(window.html('recommended'))
            .addClass('js-recommended u-allCaps outline gray js-statusUpdated')
            .attr('href', window.url(window.routes.accountRecommendations))
            .removeClass('Button-recommend');
        $('.js-recommend[data-media-id="' + mediaId + '"].primary-action')
            .css('visibility', 'hidden')
            .addClass('js-statusUpdated');
    },
};

/**
 * For redirecting when a user's card becomes invalid
 */
window.handleInvalidCard = function () {
    window.globalEvents.trigger('user:card:invalid');
};

/**
 * BORROW EVENT LISTENERS
 */
$(function () {
    var selector =
        '[data-action="borrow"]:not([data-no-linked-cards]), [data-action="borrow-simple"]:not([data-no-linked-cards])';
    $(document).on('click', selector, function (e) {
        var elem =
            $(e.target).is('[data-action="borrow"]') ||
            $(e.target).is('[data-action="borrow-simple"]')
                ? $(e.target)
                : $(e.target).closest('[data-action="borrow"]').length
                ? $(e.target).closest('[data-action="borrow"]')
                : $(e.target).closest('[data-action="borrow-simple"]');
        var mediaId = elem.attr('data-media-id');

        if (elem.attr('data-type') != 'periodicals') {
            var lendingPeriodInput = $('#lending-period-modal-' + mediaId)
                .length
                ? $('#lending-period-modal-' + mediaId)
                : $('#lending-period-' + mediaId);
            window.ajax.borrow(mediaId, lendingPeriodInput.val(), elem);

            //Remove event handler to prevent re-triggering event
            elem.off('click');
        } else if (
            elem.attr('data-type') == 'periodicals' &&
            Cookies.get('nook-eula-setting') != '1'
        ) {
            $('a#accept-nook-dynamic').attr('data-media-id', mediaId);
        } else {
            // Periodical and Nook EULA accepted
            elem.attr('data-action', 'nook').click();
        }
    });

    //For borrow and nook buttons
    $(document).on('borrowSuccess nookSuccess', function (e, elem, data) {
        var mediaId = elem.attr('data-media-id');
        var actionButton = $(
            '[data-action="borrow-simple"][data-media-id="' +
                mediaId +
                '"], [data-action="nook"][data-media-id="' +
                mediaId +
                '"]'
        );

        //Remove loading gif and action button from title elements
        $('.primary-action[data-media-id="' + mediaId + '"]').css(
            'visibility',
            'hidden'
        );
    });

    //For borrow buttons only
    $(document)
        .on('borrowSuccess', function (e, elem, data) {
            var mediaId = elem.attr('data-media-id');
            var actionButton = $(
                '[data-action="borrow-simple"][data-media-id="' + mediaId + '"]'
            );

            //Update banners
            var copy = window.html('availabilityBorrowed').toUpperCase();
            $('.title-header-bar[data-media-id="' + mediaId + '"] a')
                .html(
                    copy +
                        ' <i class="icon icon-icon-checked title-label" aria-hidden="true"></i>'
                )
                .attr('href', window.url(window.routes.accountLoans));

            //Update action buttons
            window.loading.changeButtonState(actionButton, 'borrowed');

            //Show toast
            if (
                actionButton.attr('data-type') !== 'periodicals' &&
                !data.sessionExpired
            ) {
                window.ajax.borrowSuccessToast(
                    data,
                    elem.attr('data-type-name')
                );
            }

            // Hide modal
            $('.modal').foundation('reveal', 'close');
        })
        .on('borrowRequest', function (e) {
            // Show spinner
            if ($(e.target).context.tagName !== 'BODY') {
            }
        })
        .on('borrowFailure', function (e, elem, response) {
            response = JSON.parse(response.jqXHR.responseText);
            var target = elem;
            var mediaId = target.attr('data-media-id');

            // If the user's card is invalid prompt them to link a card
            // If there's an error besides the title is out of copies, pop a toast
            // If the borrowFailure is on the body, this means an forwarded action happened and the title wasn't on the page
            //  -- Do nothing in this handler; check borrowFailure in utility handler
            if (response.errorCode === 'PatronCardFailedAuthentication') {
                if (window.OverDrive.authType === 'library') {
                    window.handleInvalidCard();
                } else {
                    // the user's card is no longer valid. Direct them to the link a card flow so that they may resolve any issues.
                    $('.js-card-error-modal').foundation('reveal', 'open');
                    $('.primary-action[data-media-id="' + mediaId + '"]').html(
                        $(
                            '.primary-action[data-media-id="' + mediaId + '"]'
                        ).attr('data-original-text')
                    );
                }
            } else if (response.sessionExpired) {
                var forwardUrl = window.location.pathname;
                if (window.location.search) {
                    forwardUrl += window.location.search + '&borrow~' + mediaId;
                } else {
                    forwardUrl += '?borrow~' + mediaId;
                }
                $('.js-reauthenticate').attr(
                    'href',
                    window.url(window.routes.accountSignIn, {
                        forward: forwardUrl,
                    })
                );
                $('#authenticate-modal').foundation('reveal', 'open');
                $('.primary-action[data-media-id="' + mediaId + '"]').html(
                    $('.primary-action[data-media-id="' + mediaId + '"]').attr(
                        'data-original-text'
                    )
                );
            } else if (response.errorCode !== 'TitleNoLongerAvailable') {
                var href = '';
                var linkText = '';
                if (
                    response.errorCode === 'PatronExceededCheckoutLimit' ||
                    response.errorCode === 'LoanAlreadyExists'
                ) {
                    href = window.url(window.routes.accountLoans);
                    linkText = window.html('loans');
                } else if (
                    response.errorCode === 'PatronExceededChurningLimit' ||
                    response.errorCode === 'PatronTypeRestrictedFromTitle'
                ) {
                    href = window.url(window.routes.supportMembers);
                    linkText = window.html('pleaseContactSupport');
                }
                var link =
                    '<a class="bold" href="' +
                    href +
                    '" tabindex="0">' +
                    linkText +
                    '</a>';
                if (window.toaster)
                    window.toaster.push(
                        '<strong>' +
                            window.html('genericError') +
                            '</strong> ' +
                            window.text('error.' + response.errorCode, {
                                link: link,
                            }),
                        'error'
                    );
                $('.primary-action[data-media-id="' + mediaId + '"]').html(
                    $('.primary-action[data-media-id="' + mediaId + '"]').attr(
                        'data-original-text'
                    )
                );
            } else if (elem.context.tagName !== 'BODY') {
                var mediaData = {
                    id: mediaId,
                    title: target.attr('data-title'),
                    cover: target.attr('artwork'),
                    typeId: target.attr('data-type'),
                    prerelease: target.attr('data-pre-release'),
                };

                openHoldConflictModal(mediaData);
            }
        });

    //For Nook buttons only
    //After accepting nook modal
    $(document).on(
        'click tap',
        '[data-action="nook"]:not([data-no-linked-cards])',
        function (e) {
            var elem = $(e.target).is('[data-action="nook"]')
                ? $(e.target)
                : $(e.target).closest('[data-action="nook"]');
            var id =
                elem.attr('id') == 'accept-nook-dynamic' ||
                Cookies.get('nook-eula-setting') == '1';

            if (id) {
                elem = $(
                    'a[data-action="nook"][data-media-id="' +
                        elem.attr('data-media-id') +
                        '"]'
                ); // Find original element
                window.ajax.borrow(elem.attr('data-media-id'), null, elem);
            } else {
                $('a#accept-nook-dynamic').attr(
                    'data-media-id',
                    elem.attr('data-media-id')
                );
            }
        }
    );

    //Update dynamic nook modal mediaID
    $('.primary-action[data-type="periodicals"]').on('click', function () {
        $('#accept-nook-dynamic').attr({
            'data-media-id': $(this).attr('data-media-id'),
            'data-type': $(this).attr('data-type'),
        });
    });
});

window.borrowFromTitleElem = function (mediaId) {
    var elem = $('.primary-action[data-media-id="' + mediaId + '"]:first');
    elem.attr('data-original-text', elem.html());
    elem.html(primaryActionLoadingGif);
    ajax.borrow(mediaId, null, elem);
};

/**
 * HOLD EVENT LISTENERS -- from the big ol' buttons
 */
$(function () {
    var selector = '[data-action="hold"]:not([data-no-linked-cards])';
    $(document)
        .on('click', selector, function (e) {
            var target = $(e.target).closest(selector);
            var mediaId = target.attr('data-media-id');

            // LHTNG-1584
            window.handleHold(target, mediaId);

            $('form#hold-dynamic-form').removeAttr('data-from-title');

            //Remove event handler to prevent re-triggering event
            $($(e.target)).off('click');
        })
        .on('holdSuccess', function (e, elem, data) {
            var mediaId = elem.attr('data-media-id');
            var holdsUrl = window.url(window.routes.accountHolds);

            // Get the user's new wait list position in order to update holds ratio modals.
            window.ajax.getHold(mediaId).done(function (media) {
                window.setDynamicHoldsRatioModals(media);
            });

            var email = $('.patron-hold-email-dynamic').val();

            // Open Success modal
            var title = new window.Title(window.OverDrive.mediaItems[mediaId]);
            window.globalEvents.trigger('title:hold:success', {
                titleModel: title,
            });

            $('.primary-action[data-media-id="' + mediaId + '"]').css(
                'visibility',
                'hidden'
            );

            var link =
                '<a href="' + holdsUrl + '">' + window.html('holds') + '</a>';
            $('p.pre-release-explanation').html(
                window.html('prereleaseExplanation', { link: link })
            );

            if (
                $(this).parents('.now-unavailable-button-container').length < 0
            ) {
                $(this).attr('data-action', 'held');
            }
            window.loading.changeButtonState(
                $(
                    'a[data-action="hold"][data-media-id="' +
                        mediaId +
                        '"], a[data-action="borrow"][data-media-id="' +
                        mediaId +
                        '"], a[data-action="borrow-simple"][data-media-id="' +
                        mediaId +
                        '"], a[data-action="held"][data-media-id="' +
                        mediaId +
                        '"]'
                ),
                'held'
            );

            //Change grid items to match
            $('.title-header-bar[data-media-id="' + mediaId + '"] a')
                .html(
                    window.html('onHold') +
                        ' <i class="icon icon-icon-checked title-label" aria-hidden="true"></i>'
                )
                .attr('href', holdsUrl)
                .addClass('u-allCaps');
            window.loading.updateCoverAlt(mediaId, window.text('onHold'));

            // Update holds ratio help tip class
            $('.js-holds-ratio-not-held[data-media-id="' + mediaId + '"]')
                .addClass('js-holds-ratio-held')
                .removeClass('js-holds-ratio-not-held');

            // Update prerelease tip
            $('.js-prerelease[data-media-id="' + mediaId + '"]').addClass(
                'is-held'
            );
        })
        .on('holdFailure', function (e, elem, response) {
            window.loading.changeButtonState(elem, 'hold');

            response = JSON.parse(response.responseText);
            var link = '';
            var mediaId = elem.attr('data-media-id');

            function errorToast(pageLink) {
                window.toaster.push(
                    '<strong>' +
                        window.html('genericError') +
                        '</strong> ' +
                        window.text('error.' + response.errorCode, {
                            link: pageLink,
                        }),
                    'error'
                );
            }

            if (response.sessionExpired) {
                window.gloablEvents.trigger('user:session:expired', {
                    action: 'hold~' + mediaId,
                });
            } else if (
                response.errorCode === 'PatronCardFailedAuthentication'
            ) {
                $('#hold-dynamic-modal').foundation('reveal', 'close');
                window.handleInvalidCard();
            } else if (
                response.errorCode === 'HoldAlreadyExists' ||
                response.errorCode === 'PatronExceededHoldLimit'
            ) {
                $('#hold-dynamic-modal').foundation('reveal', 'close');
                link =
                    '<a class="bold" href="' +
                    window.url(window.routes.accountHolds) +
                    '" tabindex="0">' +
                    window.html('holds') +
                    '</a>';
                if (window.toaster) errorToast(link);
            } else if (
                response.errorCode === 'PatronTypeRestrictedFromTitle' ||
                response.errorCode === 'PatronHasHoldsDisabled'
            ) {
                $('#hold-dynamic-modal').foundation('reveal', 'close');
                link =
                    '<a class="bold" href="' +
                    window.url(window.routes.supportMembers) +
                    '" tabindex="0">' +
                    window.html('pleaseContactSupport') +
                    '</a>';
                if (window.toaster) errorToast(link);
            } else {
                $('#hold-dynamic-modal').foundation('reveal', 'close');
                if (window.toaster) errorToast('');
            }

            $(
                '.primary-action[data-media-id="' +
                    elem.attr('data-media-id') +
                    '"]'
            ).html(elem.attr('data-original-text'));
        });

    /**
     * Place a hold on a title when form validates
     */
    $('form#hold-dynamic-form').on('valid.fndtn.abide', function (e) {
        var elem = $(
            '[data-action="hold"][data-media-id="' +
                $(this).attr('data-media-id') +
                '"]'
        ).first();

        //Check if hold was placed from title elem or action button
        if ($('form#hold-dynamic-form').attr('data-from-title')) {
            window.holdFromTitleElem(
                $('form#hold-dynamic-form').attr('data-media-id'),
                $('form#hold-dynamic-form [data-id="title"]').text()
            );
        } else if ($('form#hold-dynamic-form').attr('data-from-ajax')) {
            window.ajax.placeHold(
                $('form#hold-dynamic-form').attr('data-media-id'),
                $('.patron-hold-email-dynamic').val(),
                $('body')
            );
        } else {
            window.holds.holdPlaced(elem);
        }
    });
});

window.handleHold = function (target, mediaId) {
    function revealHoldModal() {
        $('#hold-dynamic-modal h2[data-id="title"]').html(
            $('[data-id="title"]').attr('data-title')
        );
        if (target.attr('data-artwork')) {
            $('[data-id="cover-image"]').attr(
                'src',
                target.attr('data-artwork')
            );
        } else {
            var art = $('div.title[data-title-id="' + mediaId + '"] img').attr(
                'src'
            );
            if (typeof art == 'undefined') {
                art = $('div.modal-container img[data-id="cover-image"]').attr(
                    'src'
                );
            }
            $('[data-id="cover-image"]').attr('src', art);
        }
        window.holds.revealHoldModal(target);
    }

    window.ajax
        .getPatronEmail()
        .done(function (email) {
            if (email != '') {
                $(
                    'input#email-dynamic, input#email-conf-dynamic, .js-recommend-email'
                ).val(email);
                $(
                    '.primary-action, [data-action]:not([data-action="main-navigation"])'
                ).addClass('valid-email');
                window.holds.holdPlaced(target);
            } else {
                revealHoldModal();
            }
        })
        .fail(function () {
            revealHoldModal();
        });
};

/**
 * HOLD FROM TITLE ELEMENT -- e.g. that little link under the cover in a carousel or grid view
 */
function openHoldFromTitleElem(
    title,
    typeId,
    typeName,
    artwork,
    mediaId,
    prereleaseDate,
    isPrerelease
) {
    var modal = $('#hold-dynamic-modal');
    $('form#hold-dynamic-form').attr('data-from-title', true);
    var button = $('.primary-action[data-media-id="' + mediaId + '"]:first');

    button.attr('data-original-text', button.text());
    button.html(primaryActionLoadingGif);

    $.when(window.ajax.getAvailability(mediaId), window.ajax.getPatronEmail())
        .done(function (availabilityResp, emailResp) {
            var availabilityData = availabilityResp[0];
            var email = emailResp[0];

            if (email != '' || availabilityData.availableCopies > 0) {
                $(
                    'input#email-dynamic, input#email-conf-dynamic, .js-recommend-email'
                ).val(email);
                $(
                    '.primary-action, [data-action]:not([data-action="main-navigation"])'
                ).addClass('valid-email');
                $('form#hold-dynamic-form').attr('data-media-id', mediaId);
                window.holdFromTitleElem(
                    mediaId,
                    availabilityData.availableCopies > 0
                );
            } else {
                $('[data-id="title"]').html(title);
                $('[data-id="format-icon"]')
                    .removeClass()
                    .addClass('icon-' + typeId);
                $('[data-id="format-name"]').html(typeName);
                $('[data-id="cover-image"]').attr('src', artwork);
                if (prereleaseDate != undefined && isPrerelease) {
                    $('[data-id="pre-release-label"]')
                        .text('Pre-release: Expected ' + prereleaseDate)
                        .removeClass('hideMe');
                } else {
                    $('[data-id="pre-release-label"]')
                        .text('')
                        .addClass('hideMe');
                }

                var labelCopy = window.html('dynamicHoldModal.placeHoldDesc', {
                    libraryName: window.OverDrive.libraryName,
                });
                if (window.OverDrive.mediaItems[mediaId].ownedCopies === 0) {
                    labelCopy = window.html('holdsRatioHelpModal.noCopiesText');
                }
                $('.js-hold-modal-label').html(labelCopy);

                $('form#hold-dynamic-form').attr('data-media-id', mediaId);

                if (
                    button.attr('data-publisher') >= '98146' &&
                    button.attr('data-publisher') <= '98155' &&
                    Cookies.get('disney-eula-setting') != '1'
                ) {
                    // Disney publisher ids are 98146 - 98155
                    $('#disney-eula-modal').foundation('reveal', 'open');
                } else {
                    modal.foundation('reveal', 'open');
                }
            }
        })
        .fail(function () {
            window.handleHold(button, mediaId);
        });
}

window.holdFromTitleElem = function (mediaId, availableCopies) {
    var elem = $('.primary-action[data-media-id="' + mediaId + '"]');

    var originalHTMLContents = $(
        '.primary-action[data-media-id="' + mediaId + '"]'
    ).html();
    elem.html(window.primaryActionLoadingGif);
    $(
        '.primary-action, [data-action]:not([data-action="main-navigation"])'
    ).addClass('valid-email');

    window.ajax.placeHold(
        mediaId,
        encodeURIComponent($('.patron-hold-email-dynamic').val()),
        elem
    );
};

window.openHoldSuccessModal = function (params) {
    var modal = $('#js-HoldSuccessModal');
    var linkUrl = window.url(window.routes.accountHolds);
    var manageHolds =
        '<a class="HoldSuccessModal-link" href="' +
        linkUrl +
        '" tabindex="0">' +
        window.html('holdSuccessModal.manageHolds') +
        '</a>';
    var successCopy;
    if (params.autoCheckoutSetting == 1) {
        successCopy = window.html('toasts.holdSuccessAutoCheckoutHtml', {
            email: params.email,
            manageHoldsLink: manageHolds,
        });
    } else {
        successCopy = window.html('toasts.holdSuccessHtml', {
            email: params.email,
            manageHoldsLink: manageHolds,
        });
    }
    modal.find('[data-id="loading-container"]').html(primaryActionLoadingGif);
    $.get(
        window.url(window.routes.mediaRelatedForHoldSuccess, {
            mediaId: params.mediaId,
        }),
        function (res) {
            $('.js-holdSuccessContent').html(res);
            modal.find('[data-id="success-message"]').html(successCopy);
            var lzld = lazyload({ src: 'data-aload' });
            $('img[data-aload]')
                // add dimensions
                .addClass('broken')
                // add blank image so it doesn't look broken (this is smaller than clear.gif)
                .attr(
                    'src',
                    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
                )
                // remove the placeholder stuff when the image is load for real
                .on('load', function () {
                    if (this.getAttribute('data-lzled')) {
                        $(this)
                            .removeAttr('broken data-aload')
                            .removeClass('broken');
                    }
                })
                // register the lazy loader watcher
                .each(function () {
                    lzld(this);
                });
            modal.find('[data-id="loading-container"]').html('');
            modal.foundation('reveal', 'open');
            if (typeof params.onLoad == 'function') params.onLoad();
        }
    );
};

// Hold success modal events
$('#js-HoldSuccessModal').on('closed.fndtn.reveal', function (e) {
    // Don't clear content if the modal needs to be reopened or the dropdown is closed
    if (
        $('.js-holds-ratio-help-modal').attr('data-holdSuccess') !== 'true' &&
        !$(e.target).hasClass('js-context-drop')
    ) {
        $('.js-holdSuccessContent').html('');
    }
    if (window.toaster) window.toaster._stickToast();
});

$('.js-holdSuccessContent').on('click tap', function (e) {
    if ($(e.target).hasClass('js-holds-ratio-help-icon')) {
        $('.js-holds-ratio-help-modal').attr('data-holdSuccess', true);
    }
});

$('.js-holds-ratio-help-modal').on('closed.fndtn.reveal', function () {
    if ($(this).attr('data-holdSuccess') === 'true') {
        $('#js-HoldSuccessModal').foundation('reveal', 'open');
        $(this).attr('data-holdSuccess', false);
    }
});

/**
 * Edit a holds email address
 */
$(document).on('valid.fndtn.abide', '#hold-email-form', function () {
    var mediaId = $('#mediaId').val();

    $.ajax({
        url: window.url(window.routes.mediaEditHold, { mediaId: mediaId }),
        type: 'PUT',
        data: {
            email: $('.patron-hold-email').val(),
            autoCheckout: window.patronSettings['auto-checkout-setting'] == 1,
        },
        success: function (data) {
            var email = $('.patron-hold-email').val();

            // Update the email on the Card manager instance
            window.card && window.card.setHoldEmail(email);
            $('#edit-hold-email-modal').foundation('reveal', 'close');
        },
        error: function (jqXHR, textStatus, errorThrown) {
            $('#edit-hold-email-modal').foundation('reveal', 'close');
            if (window.toaster)
                window.toaster.push(
                    '<strong>' +
                        window.html('genericError') +
                        '</strong> ' +
                        jqXHR.responseText,
                    'error'
                );
        },
    });
});

/**
 * WISH LIST EVENT LISTENERS
 */
$(function () {
    var elemAdd = '[data-action="wishlist-add"]:not([data-no-linked-cards])';

    $('body')
        .on('click', elemAdd, function (e) {
            window.ajax.wishListAdd(
                $(e.target).closest('a').attr('data-media-id'),
                $(e.target).closest('a')
            );
        })
        .on('wishListAddRequest', elemAdd, function (e) {
            var icon = $(e.target).closest('div').children('i');
            window.loading.animateIcon(icon, 'bounceOut');
        })
        .on('wishListAddSuccess', elemAdd, function (e, data) {
            var icon = $(e.target).closest('div').children('i');
            icon.removeClass('icon-wish-list').addClass('icon-wish-list-added');
            var mediaId = $(e.target).closest('a').attr('data-media-id');
            window.loading.changeButtonState(
                $(e.target).closest('a'),
                'wishlist-remove'
            );
            window.loading.animateIcon(icon, 'bounceIn');

            //Change state of any grid view items with same media id
            var gridIcon = $(
                'div.wishlist[data-media-id="' + mediaId + '"]'
            ).find('i');
            gridIcon.hasClass('icon-wish-list')
                ? gridIcon
                      .removeClass('icon-wish-list')
                      .addClass('icon-wish-list-added')
                : gridIcon
                      .removeClass('icon-wish-list-added')
                      .addClass('icon-wish-list');
        });

    var elemRemove = '[data-action="wishlist-remove"]';
    $('body')
        .on('click', elemRemove, function (e) {
            window.ajax.wishListRemove(
                $(e.target).closest('a').attr('data-media-id'),
                $(e.target).closest('a'),
                false
            );
        })
        .on('wishListRemoveRequest', elemRemove, function (e) {
            var icon = $(e.target).closest('div').children('i');
            window.loading.animateIcon(icon, 'bounceOut');
        })
        .on('wishListRemoveSuccess', elemRemove, function (e, data) {
            var icon = $(e.target).closest('div').children('i');
            icon.removeClass('icon-wish-list-added').addClass('icon-wish-list');
            var mediaId = $(e.target).closest('a').attr('data-media-id');
            $(e.target).closest('a').attr('data-action', 'wishlist-add');
            window.loading.changeButtonState(
                $(e.target).closest('a'),
                'wishlist-add'
            );
            window.loading.animateIcon(icon, 'bounceIn');

            //Change state of any grid view items with same media id
            var gridIcon = $('div.wishlist[data-id="' + mediaId + '"]').find(
                'i'
            );
            gridIcon.hasClass('icon-wish-list')
                ? gridIcon
                      .removeClass('icon-wish-list')
                      .addClass('icon-wish-list-added')
                : gridIcon
                      .removeClass('icon-wish-list-added')
                      .addClass('icon-wish-list');
        });
});

/**
 * WISH LIST FROM TITLE ELEMENT
 */
window.wishlistOnTitleElem = function (mediaId, elem) {
    var wishlistUrl = window.url(window.routes.accountWishlist);
    var icon = $(elem).children(':first');
    var titleName = $(
        '.title-name[data-media-id="' + mediaId + '"] a:first'
    ).text();
    $(icon).addClass('bounceOut');

    if ($(icon).hasClass('icon-wish-list')) {
        // Add to wish list
        window.ajax.wishListAdd(mediaId, elem);

        $(document)
            .on('wishListAddSuccess', elem, function () {
                $(icon)
                    .removeClass('icon-wish-list')
                    .addClass('icon-wish-list-added')
                    .removeClass('bounceOut')
                    .addClass('bounceIn');
                $(elem).attr(
                    'aria-label',
                    window.html('removeTitleFromWishList', { title: titleName })
                );

                //Update any list view items to match
                var mediaId = $(elem)
                    .closest('div.wishlist')
                    .attr('data-media-id');
                var listElem = $(
                    'a[data-action="wishlist-add"][data-media-id="' +
                        mediaId +
                        '"]'
                );
                var listIcon = listElem.prev('i');
                listElem.attr('data-action', 'wishlist-remove');
                listIcon
                    .removeClass('icon-wish-list')
                    .addClass('icon-wish-list-added');
                window.loading.animateIcon(listIcon, 'bounceIn');
                window.loading.changeButtonState(listElem, 'wishlist-remove');

                $(document).off('wishListAddSuccess');
            })
            .on(
                'wishListAddFailure',
                elem,
                function (e, jqXHR, textStatus, errorThrown) {
                    console.log(errorThrown);
                    var mediaId = $(elem)
                        .closest('div.wishlist')
                        .attr('data-media-id');

                    if (
                        jqXHR.responseText &&
                        JSON.parse(jqXHR.responseText)['sessionExpired']
                    ) {
                        var forwardUrl = window.location.pathname;
                        if (window.location.search) {
                            forwardUrl +=
                                window.location.search + '&wish~' + mediaId;
                        } else {
                            forwardUrl += '?wish~' + mediaId;
                        }
                        $('.js-reauthenticate').attr(
                            'href',
                            window.url(window.routes.accountSignIn, {
                                forward: forwardUrl,
                            })
                        );
                        $('#authenticate-modal').foundation('reveal', 'open');
                    } else {
                        if (window.toaster)
                            window.toaster.push(
                                window.html('error.defaultErrorHtml'),
                                'error'
                            );
                    }

                    var icon = $(elem).children('i');
                    window.loading.animateIcon(icon, 'bounceIn');

                    $(document).off('wishListAddFailure');
                }
            );
    } else if ($(icon).hasClass('icon-wish-list-added')) {
        // Remove from wish list
        window.ajax.wishListRemove(mediaId, elem, false);

        $(document)
            .on('wishListRemoveSuccess', elem, function () {
                $(icon)
                    .removeClass('icon-wish-list-added')
                    .addClass('icon-wish-list')
                    .removeClass('bounceOut')
                    .addClass('bounceIn');
                $(elem).attr(
                    'aria-label',
                    window.html('addTitleToWishList', { title: titleName })
                );

                //Update any list view items to match
                var mediaId = $(elem).closest('div.wishlist').attr('data-id');
                var listElem = $(
                    'a[data-action="wishlist-remove"][data-media-id="' +
                        mediaId +
                        '"]'
                );
                var listIcon = listElem.prev('i');
                listElem.attr('data-action', 'wishlist-add');
                listIcon
                    .removeClass('icon-wish-list-added')
                    .addClass('icon-wish-list');
                window.loading.animateIcon(listIcon, 'bounceIn');
                window.loading.changeButtonState(listElem, 'wishlist-add');

                $(document).off('wishListRemoveSuccess');
            })
            .on(
                'wishListRemoveFailure',
                elem,
                function (e, jqXHR, textStatus, errorThrown) {
                    var mediaId = $(elem)
                        .closest('div.wishlist')
                        .attr('data-id');
                    console.log(errorThrown);

                    if (
                        jqXHR.responseText &&
                        JSON.parse(jqXHR.responseText)['sessionExpired']
                    ) {
                        var forwardUrl =
                            window.location.pathname + window.location.search;
                        $('.js-reauthenticate').attr(
                            'href',
                            window.url(window.routes.accountSignIn, {
                                forward: forwardUrl,
                            })
                        );
                        $('#authenticate-modal').foundation('reveal', 'open');
                    } else {
                        if (window.toaster)
                            window.toaster.push(
                                window.html('error.defaultErrorHtml'),
                                'error'
                            );
                    }

                    var icon = $(elem).children('i');
                    window.loading.animateIcon(icon, 'bounceIn');

                    $(document).off('wishListRemoveFailure');
                }
            );
    }
};

/**
 * PATRON MEDIA STATUS EVENT LISTENERS
 */
$(function () {
    var items = [];
    var wishListItems = [];

    $('body')
        .on('mediaStatusRequest', function () {
            $(
                '[data-action]:not([data-no-linked-cards]):not(.js-statusUpdated)'
            ).each(function () {
                var action = $(this).attr('data-action');
                if (action == 'wishlist-add' || action == 'wishlist-remove') {
                    var icon = $(this).closest('div').children('i');
                    window.loading.animateIcon(icon, 'bounceOut');
                }
            });
        })
        .on('mediaStatusSuccess', function (e, data) {
            var elem;

            // TODO: Remove
            // $('.media-container.loading').css('display', 'none');
            // if (typeof toggleListViewFeature == 'function') {
            //     toggleListViewFeature();
            // }

            async.parallel(
                [
                    function (callback) {
                        //LOOP ALL BORROWED TITLES
                        if (data.onCheckouts != null) {
                            for (var x = 0; x < data.onCheckouts.length; x++) {
                                var titleId = data.onCheckouts[x];
                                items.splice(items.indexOf(+titleId), 1);

                                //Handle grid view items
                                $(
                                    '.title-header-bar[data-media-id="' +
                                        titleId +
                                        '"] a'
                                )
                                    .html(
                                        window.html('borrowed') +
                                            ' <i class="icon icon-icon-checked title-label" aria-hidden="true"></i>'
                                    )
                                    .attr(
                                        'href',
                                        window.url(window.routes.accountLoans)
                                    )
                                    .addClass('u-allCaps');
                                window.loading.updateCoverAlt(
                                    titleId,
                                    window.text('borrowed')
                                );
                                $(
                                    '.primary-action[data-media-id="' +
                                        titleId +
                                        '"]'
                                ).addClass('invisible js-statusUpdated');

                                //Handle list view items
                                elem = $(
                                    '[data-action="borrow"][data-media-id="' +
                                        titleId +
                                        '"], [data-action="borrow-simple"][data-media-id="' +
                                        titleId +
                                        '"], [data-action="hold"][data-media-id="' +
                                        titleId +
                                        '"], [data-action="nook"][data-media-id="' +
                                        titleId +
                                        '"]'
                                );
                                window.loading.changeButtonState(
                                    elem,
                                    'borrowed',
                                    true
                                );
                                //Change banner text
                                $(
                                    '[data-banner][data-media-id="' +
                                        titleId +
                                        '"]'
                                ).text(
                                    window
                                        .html('availabilityBorrowed')
                                        .toUpperCase()
                                );
                            }
                        }

                        callback();
                    },
                    function (callback) {
                        //LOOP ALL HELD TITLES
                        if (data.onHolds != null) {
                            for (var y = 0; y < data.onHolds.length; y++) {
                                //If title is already borrowed do not take action
                                if (
                                    data.onCheckouts.indexOf(data.onHolds[y]) ==
                                    -1
                                ) {
                                    var titleId = data.onHolds[y];
                                    items.splice(items.indexOf(+titleId), 1);

                                    $(
                                        '.title-header-bar[data-media-id="' +
                                            titleId +
                                            '"] a'
                                    )
                                        .html(
                                            window.html('onHold') +
                                                ' <i class="icon icon-icon-checked title-label" aria-hidden="true"></i>'
                                        )
                                        .attr(
                                            'href',
                                            window.url(
                                                window.routes.accountHolds
                                            )
                                        )
                                        .addClass('u-allCaps');
                                    window.loading.updateCoverAlt(
                                        titleId,
                                        window.text('onHold')
                                    );

                                    $(
                                        '.primary-action[data-media-id="' +
                                            titleId +
                                            '"]'
                                    ).addClass('invisible js-statusUpdated');

                                    //Handle list view items
                                    elem = $(
                                        '[data-action="hold"][data-media-id="' +
                                            titleId +
                                            '"], [data-action="borrow"][data-media-id="' +
                                            titleId +
                                            '"]'
                                    );
                                    window.loading.changeButtonState(
                                        elem,
                                        'held'
                                    );

                                    //Change banner text
                                    $(
                                        '[data-banner][data-media-id="' +
                                            titleId +
                                            '"]'
                                    ).text(window.text('onHold'));

                                    // Update holds ratio help tip class
                                    $(
                                        '.js-holds-ratio-not-held[data-media-id="' +
                                            titleId +
                                            '"]'
                                    )
                                        .addClass('js-holds-ratio-held')
                                        .removeClass('js-holds-ratio-not-held');

                                    // Update prerelease tip
                                    $(
                                        '.js-prerelease[data-media-id="' +
                                            titleId +
                                            '"]'
                                    ).addClass('is-held');
                                }
                            }
                        }

                        callback();
                    },
                    function (callback) {
                        //LOOP ALL RECOMMENDED TITLES
                        if (data.recommendedToLibrary != null) {
                            for (
                                var z = 0;
                                z < data.recommendedToLibrary.length;
                                z++
                            ) {
                                var titleId = data.recommendedToLibrary[z];

                                if (
                                    window.OverDrive.mediaItems[titleId] &&
                                    !window.OverDrive.mediaItems[titleId]
                                        .isAvailable &&
                                    !window.OverDrive.mediaItems[titleId]
                                        .isHoldable
                                ) {
                                    window.loading.updateRecommendationState(
                                        titleId
                                    );
                                }
                            }
                        }

                        callback();
                    },
                    function (callback) {
                        //LOOP ALL TITLES ON WISH LIST
                        if (data.onWishlist != null) {
                            for (var z = 0; z < data.onWishlist.length; z++) {
                                var titleId = data.onWishlist[z];
                                var titleName = $(
                                    '.title-name[data-media-id="' +
                                        titleId +
                                        '"] a:first'
                                ).text();

                                $(
                                    '.wishlist[data-media-id="' +
                                        titleId +
                                        '"] i'
                                )
                                    .removeClass('icon-wish-list')
                                    .addClass(
                                        'icon-wish-list-added js-statusUpdated'
                                    );
                                $(
                                    '.wishlist[data-media-id="' +
                                        titleId +
                                        '"] a'
                                ).attr(
                                    'aria-label',
                                    window.html('removeTitleFromWishList', {
                                        title: titleName,
                                    })
                                );

                                //Handle list view items
                                elem = $(
                                    '[data-action="wishlist-add"][data-media-id="' +
                                        titleId +
                                        '"]'
                                );
                                var icon = elem.closest('div').children('i');
                                window.loading.animateIcon(icon, 'bounceIn');
                                window.loading.changeButtonState(
                                    elem,
                                    'wishlist-remove'
                                );
                                icon.removeClass('icon-wish-list').addClass(
                                    'icon-wish-list-added'
                                );
                            }
                        }

                        callback();
                    },
                ],
                function (err, results) {
                    //Stop spinner and change state on remaining items
                    for (var i = 0; i < items.length; i++) {
                        var elem = $(
                            '[data-action][data-media-id="' +
                                items[i] +
                                '"]:not(.lending-options)'
                        );

                        elem.each(function () {
                            window.loading.changeButtonState(
                                $(this),
                                $(this).attr('data-action'),
                                true
                            );
                        });
                    }

                    //Get remaining wish list items
                    for (var j = 0; j < wishListItems.length; j++) {
                        var elem = $(
                            '[data-action="wishlist-add"][data-media-id="' +
                                wishListItems[j] +
                                '"]'
                        );

                        var icon = elem.closest('div').children('i');
                        window.loading.animateIcon(icon, 'bounceIn');
                        window.loading.changeButtonState(
                            $(this),
                            $(this).attr('data-action'),
                            true
                        );
                    }
                }
            );

            var location = window.location.toString();

            //Only process here if not on the details page
            if (location.indexOf('/media/') == -1) {
                processInstantForwardActions(
                    window.location.pathname +
                        (window.location.search ? window.location.search : '')
                );
            }

            $('.carousel.loaded').each(function () {
                if (!bowser.mobile && !bowser.tablet) {
                    Ps.update(this);
                    hideCarouselArrows(this);
                }
            });

            $('.media-container.loading').css('display', 'none');
            $('.media-container.loaded')
                .css('display', 'block')
                .promise()
                .done(function () {
                    $(document).trigger('mediaItemsLoaded');
                });
            $('.js-rtlLoader').removeClass('u-hideMe');

            $('[data-action]:not([data-action="main-navigation"])').removeClass(
                'disabled'
            );
        })
        .on('mediaStatusFailure', function (e, jqXHR) {
            $('[data-action]:not([data-action="main-navigation"])').each(
                function () {
                    if (
                        $(this).attr('data-action') == 'wishlist-add' ||
                        $(this).attr('data-action') == 'wishlist-remove'
                    ) {
                        window.loading.animateIcon(
                            $(this).closest('div').children('i'),
                            'bounceIn'
                        );
                    }
                    window.loading.changeButtonState(
                        $(this),
                        $(this).attr('data-action'),
                        true
                    );
                }
            );

            $('.js-rtlLoader').removeClass('u-hideMe');

            $('.carousel.loaded').each(function () {
                if (!bowser.mobile && !bowser.tablet) {
                    Ps.update(this);
                    hideCarouselArrows(this);
                }
            });
        });

    var location = window.location.toString();
    if (
        $('body').attr('authenticated') != 'false' &&
        location.indexOf('/holds') == -1
    ) {
        $(
            '[data-action]:not([data-action="main-navigation"]), .primary-action, .js-banner-link[data-banner], [data-media-id]'
        ).each(function () {
            var mediaId =
                typeof $(this).attr('data-media-id') === 'undefined'
                    ? $(this).attr('data-id')
                    : $(this).attr('data-media-id');
            if (items.indexOf(+mediaId) < 0 && mediaId) {
                items.push(+mediaId);
                wishListItems.push(+mediaId);
            }
        });
        //hack to get rid of extra call on media details page since the button was refactored to not use title-actions
        if (
            (items.length === 1 && location.indexOf('media/' + items[0]) < 0) ||
            items.length > 1
        ) {
            window.ajax.getMediaStatus(items);
        }
    } else {
        $('[data-action]:not([data-action="main-navigation"])').each(
            function () {
                window.loading.changeButtonState(
                    $(this),
                    $(this).attr('data-action'),
                    true
                );
            }
        );

        $('.media-container.loading').css('display', 'none');
        $('.media-container.loaded').css('display', 'block');
        $('.media-container.loaded.grid-view').css('display', 'block');
        $('.js-rtlLoader').removeClass('u-hideMe');

        if ($('body').attr('no-linked-cards') === 'true') {
            $(document).ready(function () {
                setTimeout(function () {
                    $(document).trigger('mediaStatusNoCard');
                }, 200);
            });
        }

        $('.carousel.loaded').each(function () {
            if (!bowser.mobile && !bowser.tablet) {
                Ps.update(this);
                hideCarouselArrows(this);
            }
        });
    }
});

/**
 * LENDING PERIOD OPTIONS EVENT LISTENERS
 */
$(function () {
    $('[data-lending-period-dropdown]')
        .on('lendingPeriodsRetrieved', function (e, data) {
            var lendingPeriodList = $('.js-lending-period-list');
            lendingPeriodList.empty(); // Clear any remaining options
            for (var i = 0; i < data.items.length; i++) {
                var days;
                if (data.items[i].units == 'days') {
                    days = data.items[i].lendingPeriod;
                } else {
                    // minutes
                    days = data.items[i].lendingPeriod / 1440;
                }
                var selectedClass = data.items[i].isPreferredLendingPeriod
                    ? 'is-checked default'
                    : '';
                var selectedAria = data.items[i].isPreferredLendingPeriod
                    ? 'aria-selected="true"'
                    : 'aria-selected="false"';
                var selectedLabel = data.items[i].isPreferredLendingPeriod
                    ? window.html('selected')
                    : '';

                var content =
                    '<li role="menuitem" tabindex="-1" class="LendingPeriods-lendingPeriodListItem"><a tabindex="-1" href="" role="button"' +
                    selectedAria +
                    ' aria-label="' +
                    window.html('borrowButtonLabel', { days: days }) +
                    selectedLabel +
                    '" class="' +
                    selectedClass +
                    ' lending-option LendingPeriods-lendingPeriodListItemLink js-lending-period-option" data-text="' +
                    window.html('forXDays', { lendingPeriod: days }) +
                    '" data-days="' +
                    days +
                    '" id="lending-' +
                    days +
                    '-days">' +
                    window.html('xDays', { lendingPeriod: days }) +
                    '</a></li>';

                lendingPeriodList.append(content);

                if (data.items[i].isPreferredLendingPeriod) {
                    var that = this;
                    setTimeout(
                        function (that, days) {
                            $(that)
                                .prev(
                                    'a[data-media-id="' +
                                        $(that).attr('data-media-id') +
                                        '"]'
                                )
                                .children('.borrow-sub-text')
                                .text(
                                    window.text('forXDays', {
                                        lendingPeriod: days,
                                    })
                                );
                        },
                        0,
                        that,
                        days
                    );
                    $(
                        'input#lending-period-' + $(this).attr('data-media-id')
                    ).val(days);
                }
            }

            window.loading.changeButtonState(
                $(this).prev('[data-action="borrow"]'),
                'borrow'
            );

            //If on the details page: execute forwarded action after lending periods retrieved
            var location = window.location.toString();
            if (location.indexOf('/media/') > -1) {
                processInstantForwardActions(
                    window.location.pathname +
                        (window.location.search ? window.location.search : '')
                );
            }
        })
        .each(function () {
            //Only check media status if button has display other than none
            if (
                ($(this).parents('.now-available-button-container').length ==
                    0 &&
                    !$(this).closest('.action-buttons').length) ||
                ($(this).closest('.action-buttons').length &&
                    getComputedStyle(
                        $(this).closest('.action-buttons')[0],
                        null
                    ).display != 'none') ||
                $('.media-container.grid-view').length
            ) {
                if (
                    $('body').attr('authenticated') == 'false' &&
                    $('body').attr('no-linked-cards') == 'false'
                ) {
                    var mediaId = $(this).attr('data-media-id');
                    var forwardUrl = window.url(window.routes.mediaId, {
                        id: mediaId,
                    });
                    var signInUrl = window.url(window.routes.accountSignIn, {
                        forward: forwardUrl,
                    });
                    var signInLink =
                        '<a class="lending-option-link js-lending-period-link" tabindex="-1" aria-label="' +
                        window.html('lendingPeriodTextLabelNoAuth') +
                        '" href="' +
                        signInUrl +
                        '">' +
                        window.html('signIn') +
                        '</a>';
                    var content = window.html(
                        'lendingPeriodTextNoAuthWithLinkHtml',
                        { link: signInLink }
                    );
                    $(
                        'ul#lending-period-options-' +
                            $(this).attr('data-media-id')
                    ).append(content);
                } else {
                    //If no linked cards populate lending period dropdown
                    var attr = $(this).attr('data-no-linked-cards');
                    if (typeof attr !== 'undefined' && attr !== false) {
                        var addCardLink =
                            '<a class="lending-option-link js-lending-period-link" tabindex="-1" aria-label="' +
                            window.html('lendingPeriodTextLabelNoCard') +
                            '" data-reveal-id="link-card-modal" href="javascript:void(0)">' +
                            window.html('addACard') +
                            '</a>';
                        var content = window.html(
                            'lendingPeriodTextNoCardWithLinkHtml',
                            { link: addCardLink }
                        );
                        $(
                            'ul#lending-period-options-' +
                                $(this).attr('data-media-id')
                        ).append(content);
                    } else {
                        window.ajax.getLendingPeriods(
                            $(this).attr('data-media-id'),
                            $(this)
                        );
                    }
                }
            }
        });

    window.globalEvents.on('lendingPeriods:fetch:fail', function () {
        $('.js-lending-period-list').html(
            '<span>' + window.html('error.errorTryAgain') + '</span>'
        );
    });

    /*
     * Change lending period options and control focus with keyboard
     */
    $('body').on('click keydown', '[data-lending-ul] li a', function (e) {
        if (e.type == 'click' || e.which == 13 || e.which == 32) {
            e.preventDefault();

            var dropdownButton = $(e.target)
                .closest('ul')
                .prev('[data-lending-period-dropdown]');
            var mediaId = dropdownButton.attr('data-media-id');

            var lendingDropdown = $(
                'ul#lending-period-options-modal-' + mediaId
            ).length
                ? 'ul#lending-period-options-modal-' + mediaId
                : 'ul#lending-period-options-' + mediaId;
            $(lendingDropdown + ' li a').each(function () {
                $(this)
                    .removeClass('checked is-checked')
                    .attr({
                        'aria-selected': 'false',
                        'aria-label': $(this).attr('aria-label')
                            ? $(this)
                                  .attr('aria-label')
                                  .replace(window.html('selected'), '')
                            : '',
                    });
            });

            $(
                lendingDropdown +
                    ' li a[data-days="' +
                    $(this).data('days') +
                    '"]'
            )
                .addClass('checked is-checked')
                .attr({
                    'aria-selected': 'true',
                    'aria-label': window.html('borrowButtonLabel', {
                        days: $(this).data('days'),
                    }),
                });

            dropdownButton
                .prev('[data-action="borrow"]')
                .find('span.borrow-sub-text')
                .text($(this).data('text'));

            var lendingPeriodInput = $('#lending-period-modal-' + mediaId)
                .length
                ? $('#lending-period-modal-' + mediaId)
                : $('#lending-period-' + mediaId);

            lendingPeriodInput.val($(this).data('days'));

            if (!$(this).hasClass('default')) {
                dropdownButton
                    .prev('[data-action="borrow"]')
                    .addClass('changed');
            } else {
                dropdownButton
                    .prev('[data-action="borrow"]')
                    .removeClass('changed');
            }

            //$('.js-lending-period-option').attr('tabindex', -1);
            $('.js-lending-period-list').hide();
            $('.js-lending-period-dropdown-button').focus();
        } else if (e.which == 40) {
            //Down arrow pressed move focus forward
            e.preventDefault();
            $(this).closest('li').next('li').children('a').focus();
        } else if (e.which == 38) {
            //Up arrow pressed move focus back
            e.preventDefault();

            //If first item in list go back to dropdown button
            if ($(this).closest('li').is(':first-child')) {
                $(this).closest('ul').prev('button.dropdown').focus();
            } else {
                $(this).closest('li').prev('li').children('a').focus();
            }
        }
    });

    $('.lending-period-dropdown').on('keydown', function (e) {
        if (e.which == 40) {
            //Down arrow pressed move focus to first item
            e.preventDefault();
            var dropDown = $(this).data('dropdown');
            $('ul#' + dropDown)
                .children('li')
                .first()
                .children('a')
                .focus();
        }
    });

    //Control lending period dropdown tabindex
    $('[data-lending-ul], ul.signin-dropdown')
        .on('opened.fndtn.dropdown', function () {
            $(this).find('a').attr('tabindex', '0');
        })
        .on('closed.fndtn.dropdown', function () {
            var mediaId = $(this).attr('data-media-id');
            $(this).find('a').attr('tabindex', '-1');
            $('input#lending-period-' + mediaId).trigger('change');
        });

    //Focus on borrow button after selecting new lending period
    $('[data-lending-period-input]').on('change', function () {
        $(this).prev('[data-action="borrow"]').focus();
    });
});

/**
 * Handle star ratings. Star ratings event listeners
 */
$(function () {
    //Action on star click (Set rating)
    $('.star-ratings-container.editable.is-old').on(
        'click',
        '.icon-star',
        function (e) {
            var $target = $(e.target);
            var $container = $target.closest('.star-ratings-container');
            var rating = $target.data('index');

            selectStarRating($container, rating, false);
        }
    );

    //Retrieve all star ratings
    $('body').on('mediaStatusSuccess', function (e, data) {
        for (var mediaId in data.starRatings) {
            var $container = $(
                '.star-ratings-container.is-old[data-media-id="' +
                    mediaId +
                    '"]'
            );
            var rating = data.starRatings[mediaId];

            setUserStarRating($container, rating, {
                setRating: true,
                isHover: false,
            });
        }
        $(this).trigger('userStarRatingSet'); // Allow forwarded star action to be processed
    });

    //Control hover state
    $('.star-ratings-container.editable.is-old .icon-star').hover(function (e) {
        var $this = $(this);
        var $container = $this.closest('.star-ratings-container');
        var userRating = $container.data('user-rating');

        $container.addClass('user-rating');

        var hypotheticalRating = $this.data('index');
        fillStars($container, hypotheticalRating, {
            setRating: false,
            isHover: true,
        });
    });

    $('.star-ratings-container.editable.is-old').on('mouseleave', function () {
        var $this = $(this);
        var $container = $this.closest('.star-ratings-container');
        var userRating = $container.data('user-rating');
        var hasUserRating = userRating > 0;

        if (!hasUserRating) {
            $container.removeClass('user-rating');

            fillStars($container, $container.data('global-rating'), {
                setRating: false,
                isHover: true,
            });
        } else {
            fillStars($container, userRating, {
                setRating: false,
                isHover: true,
            });
        }
    });

    window.globalEvents.on('rating:set:fail', function () {
        var link =
            '<a class="bold" href="' +
            window.url(window.routes.supportMembers) +
            '" tabindex="0">' +
            window.html('pleaseContactSupport') +
            '</a>';
        if (window.toaster)
            window.toaster.push(
                '<strong>' +
                    window.html('genericError') +
                    '</strong> ' +
                    window.text('error.errorContactSupport', { link: link }),
                'error'
            );
    });

    window.globalEvents.on('rating:delete:fail', function () {
        var link =
            '<a class="bold" href="' +
            window.url(window.routes.supportMembers) +
            '" tabindex="0">' +
            window.html('pleaseContactSupport') +
            '</a>';
        if (window.toaster)
            window.toaster.push(
                '<strong>' +
                    window.html('genericError') +
                    '</strong> ' +
                    window.text('error.errorContactSupport', { link: link }),
                'error'
            );
    });
});

/**
 * Handle recommend to library
 */
$(function () {
    $(document).on(
        'click',
        '.js-recommend:not([data-no-auth]):not([data-no-linked-cards]):not(.js-recommended)',
        function (e) {
            // This check will be refactored out soon..
            if (
                window.OverDrive.isAuthenticated &&
                window.OverDrive.hasLinkedCards
            ) {
                var $genericElem = $(
                    '.js-recommend[data-media-id="' +
                        $(this).attr('data-media-id') +
                        '"]'
                );
                var $gridElem = $(
                    '.primary-action[data-media-id="' +
                        $(this).attr('data-media-id') +
                        '"]:first'
                );
                var $listElem = $(
                    '.js-recommend[data-media-id="' +
                        $(this).attr('data-media-id') +
                        '"]:not(.primary-action)'
                );

                $gridElem.attr('data-original-text', $gridElem.html());
                $gridElem.html(primaryActionLoadingGif);

                var title = $(this).data('title');
                var author = $(this).data('author');
                var typeId = $(this).data('type');
                var typeName = $(this).data('type-name');
                var artwork = $(this).data('artwork');
                var mediaId = $(this).data('media-id');

                $('[data-id="title"]').html(title);
                $('[data-id="author"]').html(author);
                $('[data-id="format-icon"]')
                    .removeClass()
                    .addClass('icon-' + typeId);
                $('[data-id="format-name"]').html(typeName);
                $('[data-id="cover-image"]').attr('src', artwork);

                $('form#recommend-dynamic-form').attr('data-media-id', mediaId);

                if (!$genericElem.hasClass('js-recommended')) {
                    window.ajax
                        .getPatronEmail()
                        .done(function (email) {
                            if (email != '') {
                                $('.js-recommend').addClass('valid-email');
                                $('.js-recommend-email').val(email).hide();
                            }

                            $('.js-recommend-modal').foundation(
                                'reveal',
                                'open'
                            );
                        })
                        .fail(function () {
                            $('.js-recommend-modal').foundation(
                                'reveal',
                                'open'
                            );
                        });
                }
            }
        }
    );

    $('.js-recommendations-form').on('valid.fndtn.abide', function (e) {
        var id = $('.js-recommendations-form').attr('data-media-id');
        var addHold = $('.js-recommend-checkbox:checked').val();
        var notifyIfPurchased = true;
        var email = $('.js-recommend-email').val();

        window.ajax.addRecommendation(id, addHold, notifyIfPurchased, email);
    });

    $(document).on('recommendationFailure', function (e, data) {
        var $gridElem = $(
            '.primary-action[data-media-id="' + data.mediaId + '"]'
        );
        $gridElem.html($gridElem.attr('data-original-text'));

        var errorMsg = data.responseText || window.text('errorOccurred');

        if (errorMsg === 'PatronExceededRecommendationsLimit') {
            $('.js-recommendation-limit-modal').foundation('reveal', 'open');
        } else if (data.statusText === 'Unauthorized') {
            var forwardUrl = window.location.pathname;
            if (window.location.search) {
                forwardUrl +=
                    window.location.search + '&recommend~' + data.mediaId;
            } else {
                forwardUrl += '?recommend~' + data.mediaId;
            }
            $('.js-reauthenticate').attr(
                'href',
                window.url(window.routes.accountSignIn, { forward: forwardUrl })
            );
            $('#authenticate-modal').foundation('reveal', 'open');
            //  Consider for following if: data.errorCode === 'PatronCardFailedAuthentication'
        } else if (
            data.statusText === 'Unauthorized' ||
            (data.responseJSON && data.responseJSON.sessionExpired === true)
        ) {
            if (window.OverDrive.authType === 'library') {
                window.handleInvalidCard();
            } else {
                // the user's card is no longer valid. Direct them to the link a card flow so that they may resolve any issues.
                $('.js-card-error-modal').foundation('reveal', 'open');
            }
        } else {
            if (window.toaster) window.toaster.push(errorMsg, 'error');
            $('.js-recommend-modal').foundation('reveal', 'close');
        }
    });

    $('.js-recommend-modal').on('closed.fndtn.reveal', function () {
        //Remove spinners
        var mediaId = $(this).find('form').attr('data-media-id');
        var $gridElem = $('.primary-action[data-media-id="' + mediaId + '"]');
        var $listElem = $(
            '.js-recommend[data-media-id="' +
                mediaId +
                '"]:not(.primary-action)'
        );

        $gridElem.html($gridElem.attr('data-original-text'));
    });
});

$(document).on('click', 'a[data-reveal-id]', function (e) {
    e.preventDefault();
});

$(document).ready(function () {
    $(document).on(
        'click',
        '[data-action][data-no-linked-cards], .icon-star[data-no-linked-cards]',
        function () {
            var strippedQuerystring = window.location.search.replace(
                /([?&]no-linked-card=(?:true|false)&?)/i,
                function (match) {
                    var lastChar = match[match.length - 1];
                    if (lastChar === '&') {
                        // there's more after us; when we're removed we need to use our beginning seperator to replace the ampersand
                        return match[0];
                    } else {
                        // nothing after us; can just replace with nothing
                        return '';
                    }
                }
            );

            var forwardUrl =
                window.location.pathname +
                window.location.search.replace(
                    /(&|\?)no-linked-card(=true|\=false)/,
                    ''
                );
            var action = '';
            if ($(this).hasClass('icon-star')) {
                action =
                    'star~' +
                    $(this).parent().attr('data-media-id') +
                    '~' +
                    $(this).data('index'); // Add star rating
            } else {
                action =
                    $(this).attr('data-action') +
                    '~' +
                    $(this).attr('data-media-id');
            }
            var initiateCardLinkUrl = window.url(
                window.routes.accountStartCardLink,
                { forward: forwardUrl, action: action }
            );
            $('#add-card-button').attr('href', initiateCardLinkUrl);
            $('#link-card-modal').foundation('reveal', 'open');
        }
    );

    /* Reset star rating when user has no linked card and close link a car modal */
    $('.link-card-modal').on('close.fndtn.reveal', function () {
        var container = $('.star-ratings-container');
        container.data('user-rating', null).removeClass('user-rating');
        fillStars(container, Math.round(container.data('global-rating')));
    });
});

//Click on link when clicking wishlist icon
$(document).on('click', '.wishlist-container i', function () {
    $(this).next('a').click();
});

window.setDynamicHoldsRatioModals = function (media) {
    media = media || {};
    $('a#holds-explanation').each(function () {
        if ($(this).attr('data-holdListPosition') != '') {
            $(this).attr(
                'onclick',
                'dynamicHoldsRatioModal(' +
                    $(this).attr('data-holdsCount') +
                    ', ' +
                    $(this).attr('data-ownedCopies') +
                    ', ' +
                    $(this).attr('data-holdsRatio') +
                    ', ' +
                    $(this).attr('data-holdListPosition') +
                    ');'
            );
        }

        if (
            $(this).attr('data-ownedCopies') == '' ||
            $(this).attr('data-ownedCopies') == 0
        ) {
            $('#help-hold-label').addClass('hideMe');
            $('#close-ratio-help').attr(
                'aria-labelledby',
                'hold-help-label-noCopies'
            );
        }
    });

    $('a#holds-explanation-details').attr(
        'onclick',
        'dynamicHoldsRatioModal(' +
            media.holdsCount +
            ', ' +
            media.ownedCopies +
            ', ' +
            media.holdsRatio +
            ', ' +
            media.holdListPosition +
            ');'
    );
};
