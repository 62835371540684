$('.sample-modal').on('closed.fndtn.reveal', function(e) {
    $('iframe#sample-dynamic-iframe').attr('src', 'about:blank');
});

$(() =>{
    if (!window.featureManager.isEnabled('cleanupContextMenuToggle')){
        $('body').on('click keyup', '.js-context-button', function(e) {
            e.stopImmediatePropagation();

            if (e.type === 'click' || e.which === 32 || e.which === 13) {
                //If menu is already open close it
                var dropdown = $(this).attr('data-dropdown');

                if ($('ul#' + dropdown).offset().left > 0) {
                    Foundation.libs.dropdown.close($('#' + dropdown), $('.js-context-button[data-dropdown="' + $('#' + dropdown).attr('id') + '"]'));
                    $('ul#' + dropdown + ' li a').attr('tabindex', -1);
                } else {
                    $(document).foundation('dropdown', 'closeall');
                    $('ul#' + dropdown + ' li a').attr('tabindex', 0);
                    Foundation.libs.dropdown.open($('#' + dropdown), $('.js-context-button[data-dropdown="' + $('#' + dropdown).attr('id') + '"]'));
                    $(this).focus();
                }
            }
        });
    }
});


$('body').on('closed.fndtn.dropdown', '.js-context-drop', function(e) {
    $(e.target).find('a').attr('tabindex', '-1');
});

$('body').on('opened.fndtn.dropdown', '.js-context-drop', function(e) {
    $(e.target).find('a').attr('tabindex', '0');
});


$('body').on('click', 'li.read-sample a, .button.sample, .js-details-cover-sample', function(e) {
    let signInUrl = window.url(window.routes.accountSignIn, {forward: window.location.href});
    let performSampleSignInRedirect = window.featureManager.isEnabled('sample-signin-redirect')
        && !window.OverDrive.isAuthenticated
        && !window.OverDrive.allowAnonymousSampling;
    if (performSampleSignInRedirect) {
        e.stopImmediatePropagation();
        window.location.assign(signInUrl);
    } else {
        // If in mobile or in-app open sample link in new window. Else open in modal.
        var inApp = Cookies.get('omcbrowse') !== undefined;
        var target = $(e.target).closest('a');
        var isMediaDoTitle = false;

        //for samples on the home page 
        if (target.attr('media-do-format')) {
            isMediaDoTitle = target.attr('media-do-format') === "true" ? true : false;
        }

        if (isMediaDoTitle === false && window.OverDrive.mediaItems) {
            var title = window.OverDrive.mediaItems[target.attr('data-media-id')];
            var formats = title['formats'];
            formats.forEach(ele => {
                if (ele['id'] === 'ebook-media-do') {
                    isMediaDoTitle = true;
                }
            });
        }

        if ($(document).width() <= 640 ||
                target.attr('data-type') === 'video' ||
                target.attr('data-metro') || inApp ||
                (isMediaDoTitle && window.featureManager.isEnabled('open-mediado-samples-in-tab'))) {
            target.attr({target: '_blank', href: target.attr('data-href')}).removeAttr('data-reveal-id');
        } else {
            target.attr('data-reveal-id', 'sample-dynamic-modal');

            if (target.attr('data-href')) {
                $('iframe#sample-dynamic-iframe').attr('src', target.attr('data-href'));
                $('.modal-title[data-sample-title]').html(target.attr('data-title'));
                $(document).foundation('reveal', 'reflow');
            }
        }
    }
});
