let { html } = window;

export default ({ coverSrc, title, author, type, hideEmail, patronEmail, csrfToken, rid }) => `
<div class="row">
    <div class="small-4 columns no-padding-left no-padding-right">
        <img src="${ coverSrc }" alt="${ html('coverImage') }"/>
    </div>

    <div class="small-8 columns RecommendModal-titlebox-left-padding no-padding-right">
        <h2 class="RecommendModal-title">${ title }</h2>
        <h3 class="RecommendModal-author js-recommend-modal-author">${ author }</h3>

        <span class="title-format-badge RecommendModal-formatBadge"><i class="icon-${ type.id }"></i> <span tabindex="0"></span>${ window.html(type.id) }</span>
    </div>
</div>
<form id="recommend-dynamic-form" class="js-recommendations-form" data-abide="ajax">
    <div class="row RecommendModal-inputContainer">

        <p class="RecommendModal-label" tabindex="0">
            ${ html('recommendToLibraryModal.onHoldIfPurchased') }
        </p>

        <div>
            <input id="email-dynamic-recommend-${rid}" class="RecommendModal-emailInput ${ hideEmail } js-recommend-email" placeholder="${ html('emailAddress') }" title="${ html('emailAddress') }" aria-label="${ html('emailAddress') }"  value="${ patronEmail }" type="email" pattern="email" required />
            <small class="error">${ html('emailRequiredError') }</small>
        </div>
        <div>
            <input id="email-conf-dynamic-recommended-${rid}" class="RecommendModal-emailInput ${ hideEmail } js-recommend-email" placeholder="${ html('confirmEmail') }" title="${ html('confirmEmail') }" aria-label="${ html('confirmEmail') }" value="${ patronEmail }" type="email" pattern="email"  required data-abide-validator="required equalto email" data-validator-equalto="email-dynamic-recommend-${rid}" />
            <small class="error">${ html('emailsMustMatchError') }</small>
        </div>
        <span class="RecommendModal-noGuarantee">${ html('recommendSuccessModal.successMessageNoGuarantee') }</span>
    </div>
    <div class="clear" />

    <div class="button-container">
        <span class="TitleAction" />
        <button type="button" class="button cancel radius margin-small u-allCaps outline gray js-close">${ html('cancel') }</button>
    </div>
    <div class="clear" />

    <input type="hidden" class="js-recommend-csrf" id="csrf" name="_csrf" value="${ csrfToken }">
</form>
`;
