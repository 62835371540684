import TitleActionButton    from '../TitleActionButton';
import RtlPanelTemplate     from '../templates/RtlPanel.html';

const RtlPanel = Backbone.View.extend({
    initialize({ titleModel, bus, context }) {
        this.titleModel = titleModel;
        this.bus = bus;
        this.context = context;
        this.card = window.card;
        this.card._fetch.then(() => {
            this.render();
            this._bindEvents();
        });
    },

    render() {
        const patronEmail = this.card.preferredHoldEmail;

        const viewData = {
            title        : this.titleModel.get('title'),
            type         : this.titleModel.get('type'),
            author       : this.titleModel.get('firstCreatorName'),
            coverSrc     : this.titleModel.getCoverSrc(510),
            patronEmail,
            hideEmail    : patronEmail === '' ? '' : 'hideMeImportant',
            csrfToken    : $('#csrf').val(),
            html         : window.html,
            text         : window.text,
            rid          : Math.random().toString(36).substring(7)
        };

        this.$el.append(RtlPanelTemplate(viewData));

        this.$form = this.$el.find('form');

        this.titleActionButton = new TitleActionButton({
            model   : this.titleModel,
            type    : 'button',
            el      : this.$form.find('.TitleAction'),
            action  : () => this.$form.submit()
        });
    },

    _bindEvents() {
        $(document).foundation({
            abide: {
                validators: {
                    // eslint-disable-next-line object-shorthand
                    'required': function (el, required, parent) {
                        let empty = el.value.trim().length === 0;
                        if (required && empty) {
                            return false;
                        }
                        return true;
                    },
                    // eslint-disable-next-line object-shorthand
                    'equalto': function (el, required, parent) {
                        let from = document.getElementById(el.getAttribute(this.add_namespace('data-validator-equalto'))).value;
                        let to = el.value;
                        return from === to;
                    },
                    // eslint-disable-next-line object-shorthand
                    'email': function (el, required, parent) {
                        let regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
                        return regex.test(el.value);
                    }
                }
            }
        });
        this.$form
            .foundation('abide', 'reflow')
            .on('valid.fndtn.abide', () => {
                if (!this.card.preferredHoldEmail) {
                    this.card.setHoldEmail(this.$el.find('input[type="email"]:first').val().trim());
                }

                this.bus.trigger('title:recommend:post', {
                    titleModel : this.titleModel,
                    email      : this.card.preferredHoldEmail,
                    context    : this.context
                });
            });
    }
});

export default RtlPanel;
