var semver = require('semver');

import HoldConflictModal from '../../components/media/views/HoldConflictModal.js';
import TitleFulfillmentModal from '../../components/TitleFulfillmentModal/TitleFulfillmentModal.js';
import LoanTitle from '../../../../business-objects/loan-title';
import AccountLimits from '../../../../business-objects/account-limits';
import VisitorActionDisallowedModal from '../../components/visitor_action_disallowed_modal/VisitorActionDisallowedModal';
import NonHoldConflictModal from '../../components/non_hold_conflict_modal/NonHoldConflictModal';
import { Modal } from '../../components/common/modal.js';

class BorrowActions {
    constructor(bus, featureManager, globalPreferences) {
        this.bus = bus;
        this.featureManager = featureManager;
        this.globalPreferences = globalPreferences;

        this.bus
            .on('title:borrow:success',     (data) => this.borrowSuccess(data))
            .on('title:borrow:fail',        (data) => this.borrowFail(data))
            .on('title:borrow:conflict',    (data) => this._openConflictModal(data.titleModel));
    }

    borrowSuccess(data) {
        const exp = window.html('date-full', {d: data.expires});
        const linkUrl = window.url(window.routes.accountLoans);
        const loans = window.html('loans');
        const limitsUrl = window.url(window.routes.rest.account.limits);

        $.ajax(limitsUrl).then((result) => {
            let accountLimits = new AccountLimits(result.data);
            let limitFormats = this.featureManager.isEnabled('kindleLoanFormatLimit');
            let preferKindle = (Cookies.get('kindleFulfillmentAppOverride') && Cookies.get('kindleFulfillmentAppOverride') === '1')
                || (this.globalPreferences && this.globalPreferences.get('kindleFulfillment'));
            let inApp = Cookies.get('omcbrowse') !== undefined;
            let restrictVideo = this.featureManager.isEnabled('streaming-update-message') && inApp 
                    && semver.satisfies(semver.coerce(window.OverDrive.appVersion), '<3.8.6');

            let loan = new LoanTitle(data.loan,
                limitFormats, preferKindle,
                inApp, window.bowser.mac,
                this.featureManager.isEnabled('noAudiobooksForMacOS'),
                this.featureManager.isEnabled('reenableMp3DownloadMacOSPreCatalina'),
                window.OverDrive.isPreCatalinaMac,
                restrictVideo,
                this.featureManager.isEnabled('hide-disabled-formats'),
                this.featureManager.isEnabled('odapp-sunset'),
                this.featureManager.isEnabled('fix-titlemodal-magazine-display'),
                this.featureManager.isEnabled('odread-fulfillment-first'));

            let modal = new TitleFulfillmentModal({
                loan: loan,
                title: data.titleModel,
                bus: this.bus,
                featureManager: this.featureManager,
                globalPreferences: this.globalPreferences,
                accountLimits: accountLimits,
                isRenewal: !!data.context.isRenewal,
                formatLock: data.context.formatLock || null
            });
            modal.render();
        });
    }

    borrowFail(data) {
        const response = JSON.parse(data.jqXHR.responseText || '{}');
        if (response.errorCode === 'PatronCardFailedAuthentication') {
            this.bus.trigger('user:card:invalid');
        }
        else if (response.sessionExpired) {
            this.bus.trigger('user:session:expired', { action: `borrow~${data.titleModel.id}` });
        }
        else if (response.errorCode === 'CostPerCircAvailabilityFastLaneOnly' || response.errorCode === 'TitleNoLongerAvailable') {
            this._openConflictModal(data.titleModel);
        }
        else if (response.errorCode === 'CostPerCircNoBudget'){
            this._openConflictModal(data.titleModel);
        }
        else if (response.errorCode === 'RenewalFailed' || response.errorCode === 'HoldsPreventRenewal'){
            this._openConflictModal(data.titleModel);
        }
        else if (response.errorCode === 'PatronExceededLuckyDayLimit' && this.featureManager.isEnabled('LuckyDay')){
            this._openConflictModal(data.titleModel, {isLuckyDay: true});
        }
        else if (response.errorCode === 'PatronExceededCostPerCircCheckoutLimit'){
            if (window.location.href.match('account') !== null){
                if (toaster) toaster.push(`${window.text('errorCPCLimit')}`, 'error');
            } else {
                this._openConflictModal(data.titleModel);
            }
        }
        else if (response.errorCode === 'CheckoutNotAllowedForVisitor'){
            new VisitorActionDisallowedModal('borrow');
            if(window.featureManager.isEnabled('LHTNG-3387')){
                data.titleModel.set({'titleState': 'unavailable', 'availableCopies': 0, 'isAvailable': false});
            }
        }
        else if (response.errorCode === 'LendingPeriodInvalid') {
            new Modal({
                title: window.text('genericError'),
                content: `<p>${window.html('error.defaultError')}</p>`
            });
        }
        else {
            this._defaultError(response.errorCode, data.titleModel);
        }
    }

    _defaultError(errorCode, titleModel) {
        let href = '';
        let linkText = '';

        if (errorCode === 'PatronExceededCheckoutLimit' || errorCode === 'LoanAlreadyExists') {
            href = window.url(window.routes.accountLoans);
            linkText = window.html('loans');
        } else if (errorCode === 'PatronExceededChurningLimit' || errorCode === 'PatronTypeRestrictedFromTitle') {
            href = window.url(window.routes.supportMembers);
            linkText = window.html('pleaseContactSupport');
        }

        if (toaster)
        {
            const link = `<a class="bold" href="${href}" tabindex="0">${linkText}</a>`;
            if (errorCode === 'PatronExceededCheckoutLimit' && this.featureManager.isEnabled("holdRedelivery") && titleModel.get('holdListPosition') === 1){
                toaster.push(`${window.text(`holdRedelivery.loansLimit`, {link})}`, 'error');
            } else {
                toaster.push(`<strong>${window.html('genericError')}</strong> ${window.text(`error.${errorCode}`, {link})}`, 'error');
            }
        }
    }

    _openConflictModal(titleModel, options = {}) {
        // We make some assumptions about the title state here because at this point
        // the data returned by thunder is not trustworthy. IE: It will return available copies when there are none
        if(!titleModel.canPlaceHold && window.featureManager.isEnabled('nonHoldableConflictModal')) {
            new NonHoldConflictModal(titleModel, options);
            titleModel.set({
                'titleState': titleModel.get('isOnHold') ? 'onHold' : 'unavailable',
                'availableCopies': 0,
                'isAvailable': false
            });
        }
        else {
            let isRequestAgain = (/account\/loans/.test(window.location.href));
            titleModel.set({
                'titleState': isRequestAgain ? 'requestAgain' : 'hold',
                'availableCopies': 0,
                'isAvailable': false
            });
            new HoldConflictModal(this.bus, titleModel, options);
        }
    }
}

export default BorrowActions;
