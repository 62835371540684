import HoldInformationTemplate              from './HoldInformationV2.html';
import MacmillanMessage                     from '../../../../lib/utility/macmillanMessage';
const { text } = window;

export default class HoldInformationV2 extends Backbone.View {
    constructor({hideHoldsLink = false, isVisitor = false}) {
        super(...arguments);
        this.el = this.el || document.createElement('div');
        this.hideHoldsLink = hideHoldsLink;
        this.isVisitor = isVisitor;
        this.MAX_WAIT_MONTHS_TO_SHOW = 6;
        this.MAX_WAIT_WEEKS_TO_SHOW = 26;
        this.listenTo(this.model, 'change:titleState', this.removeWaitTime);
    }

    render() {
        const view = HoldInformationTemplate({
            description: this.description,
            details: this.details,
            note: this.note,
            MacmillanMessage: this.MacmillanMessage
        });
        this.$el.empty().append(view);

        return this;
    }

    removeWaitTime(){
        if(this.hideWaitTime){
            this.$el.hide();
        }
    }

    get description() {
       if (this.isPrereleaseTitle) {
            return text('expectedWaitTime.prereleaseNoWaitTime');
       } else if (this.isExpiredTitle) {
           let theText = '';
           if (this.model.get('titleState') === 'onHold'){
                theText = text('holdsRatioHelpModal.noCopiesOnPlaceHold');
           }
           else {
                theText = text('holdsRatioHelpModal.noCopiesText');
           }
           return this.hideHoldsLink ? theText : `<span>${theText}<a class="manageHoldsLink" target="_blank" href="/account/holds">${text('holdSuccessModal.manageHolds')}</a></span>`;
       } else if (this.model.allCopiesAreLuckyDay && this.model.get('titleState') === 'onHold') {
           let detailsUrl = window.url(window.routes.mediaId, {id: this.model.get('id')});
           let detailsLink = `<a style="text-decoration: underline" href="${detailsUrl}">${text('luckyDay.allLuckyDayHoldsInfoLinkText')}</a>`;
           return text('luckyDay.allLuckyDayHoldsInfo', {link: detailsLink});
       } else if (this.isFastLaneTitle) {
            return text('expectedWaitTime.advantageCaveat');
       } else if (this.isVisitor){
            return text('expectedWaitTime.rlaCaveat');
       }
       return '';
    }

    get details() {
        let details = [];
        if (!this.showWaitTimeUnavailable && !this.hideWaitTime) {
            details.push({label: text('expectedWaitTime.waitTime'), value: this.waitTimeText });
        }

        if (this.model.get('holdListPosition')){
            details.push({label: text('expectedWaitTime.placeInLine'), value: this.model.get('holdListPosition')});
        }

        let ownedCopies = this.model.get('ownedCopies') || 0;
        let totalHolds = this.model.get('holdsCount') || 0;

        details.push({label: text('expectedWaitTime.libraryCopies'), value: ownedCopies});

        if (ownedCopies > 1){
            let holdsRatio = ownedCopies && totalHolds ? (totalHolds / ownedCopies) : 0;
            let ratioDisplayValue = holdsRatio > 0 && holdsRatio < 1 ? '< 1' : Math.round(holdsRatio);
            details.push({ label: text('expectedWaitTime.peopleWaitingTotal'), value: totalHolds});
            details.push({label: text('expectedWaitTime.poepleWaitingPerCopy'), value: ratioDisplayValue});
        }
        else {
            details.push({label: text('expectedWaitTime.peopleWaiting'), value: totalHolds});
        }
        return details;
    }

    get waitTimeHtml (){
        var html = '';
        if(!this.hideWaitTime){
            html = `<span class='waitingText'>${window.text('expectedWaitTime.waitTime')}: ${this.waitTimeText}</span>`;
        }
        return html;
    }

    get waitTimeText() {
        let waitTimeText = '';

        if(!this.hideWaitTime){
            if (this.showWaitTimeUnavailable){
                waitTimeText = text('expectedWaitTime.notAvailable');
            }
            else {
                let estimatedWaitDays = Number(this.model.get('estimatedWaitDays'));
                if (estimatedWaitDays <= 7) {
                    waitTimeText = text('expectedWaitTime.availableSoon');
                }
                else if (estimatedWaitDays < (7 * this.MAX_WAIT_WEEKS_TO_SHOW)) {
                    waitTimeText = text('expectedWaitTime.aboutXWeeks', {weeks: Math.ceil(estimatedWaitDays / 7)});
                }
                else {
                    // we intentionally max out at "at least 6 months"
                    waitTimeText = text('expectedWaitTime.atLeastYMonths', { months: Math.min(Math.ceil(estimatedWaitDays / 30), this.MAX_WAIT_MONTHS_TO_SHOW)});
                }
            }
        }

        return waitTimeText;
    }

    get showWaitTimeUnavailable(){
        return this.model.get('estimatedWaitDays') === undefined || this.model.get('estimatedWaitDays') === null || this.isPrereleaseTitle || this.isVisitor || this.isExpiredTitle;
    }

    get hideWaitTime() {
        return this.isFastLaneTitle || this.model.get('titleState') === 'onHold'
            || (window.featureManager.isEnabled('betterPatronState') ? this.model.get('isBorrowed') : this.model.get('titleState') === 'borrowed');
    }

    get isExpiredTitle() {
        return window.featureManager.isEnabled('dont-mark-su-as-expired') ? this.model.isExpired 
            : this.model.get('isOwned') && !this.model.get('ownedCopies') && !this.model.allCopiesAreLuckyDay;
    }

    get isFastLaneTitle(){
        return this.model.get('isFastlane');
    }

    get isPrereleaseTitle() {
       return this.model.get('isPreReleaseTitle');
    }

    get note() {
        return [];
    }

    get MacmillanMessage(){
        return MacmillanMessage(this.model);
    }
}
