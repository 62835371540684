import RtlLimitModal from '../../components/media/views/RtlLimitModal';
import RtlSuccessModal from '../../components/media/views/RtlSuccessModal';
import VisitorActionDisallowedModal from '../../components/visitor_action_disallowed_modal/VisitorActionDisallowedModal';


class RecommendActions {
    constructor(bus) {
        this.bus = bus;

        this.bus
            .on('title:recommend:success',  (data) => this.recommendSuccess(data))
            .on('title:recommend:fail',     (data) => this.recommendFail(data));
    }

    recommendSuccess(data) {
        // Open RTL success modal
        void (new RtlSuccessModal(this.bus, data));
        data.titleModel.set('titleState', 'recommended');
        window.card.cacheReset();
    }

    recommendFail(data) {

        if (data.response === 'PatronCardFailedAuthentication') {
            this.bus.trigger('user:card:invalid');
        }
        else if (data.response.sessionExpired) {
            this.bus.trigger('user:session:expired', { action: `recommend~${data.titleModel.id}` });
        }
        else if (data.response === 'PatronExceededRecommendationsLimit') {
            void (new RtlLimitModal(this.rtlStats)); // this should never happen now LHTNG-2828
            window.card.cacheReset();
        }
        else if (data.response === 'TitleAlreadyRecommended') {
            // TODO: Lightning does not yet have a user friendly way to alert the user of an already recommended title
            toaster.push(window.text('errorOccurred'), 'error');
            $(document).foundation('reveal', 'close');
        }
        else if (data.response === 'TitleRecommendationNotAllowedForVisitor'){
            new VisitorActionDisallowedModal('recommend');
        }
        else {
            toaster.push(window.text('errorOccurred'), 'error');
            $(document).foundation('reveal', 'close');
        }
    }
}

export default RecommendActions;
