import { Modal } from '../../common/modal';
import RtlLimitTemplate from '../templates/RtlLimitModal.html';

export default class RtlNoneRemainingModal extends Modal {
    constructor (rtlStats) {
        let nextRecommendation = Math.ceil(rtlStats.nextRecommendationSeconds / 86400);

        let modalContents = {
            classes: ['RtlLimitModal'],
            title: window.html('recommendLimitModal.heading'),
            content: RtlLimitTemplate({nextRecommendation})
        };

        super(modalContents);

        this.$el.find('.confirm-button').on('click', () => {
            this.close();
        });
    }
}
