/**
 * @fileOverview
 * app.js
 * Global JS file that applies to all views.
 * @type {NodeList}
 */

$(document).ready(function () {
    $('form').on('valid invalid', function (e) {
        if (e.type != 'valid.fndtn.abide') {
            e.preventDefault();
        }
    });

    //Prevent form submit when errors are showing
    $('form').submit(function (e) {
        if ($(this).find('small.error').is(':visible')) {
            e.preventDefault();
        }
    });

    if (typeof lazyload === 'function') {
        var lzld = lazyload({ src: 'data-aload' });

        $('img[data-aload]')
            // add dimensions
            .addClass('broken')
            // add blank image so it doesn't look broken (this is smaller than clear.gif)
            .attr(
                'src',
                'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
            )
            // remove the placeholder stuff when the image is load for real
            .on('load', function () {
                if (this.getAttribute('data-lzled')) {
                    $(this)
                        .removeAttr('broken data-aload')
                        .removeClass('broken');
                }
            })
            // register the lazy loader watcher
            .each(function () {
                lzld(this);
            });
    } else {
        $('img[data-aload]').attr('src', function () {
            return $(this).attr('data-aload');
        });
    }

    $('[data-forward]').attr(
        'href',
        $('[data-forward]').attr('href') +
            '?forward=' +
            window.location.pathname +
            stripNoLinkedCardQuery(location.search) +
            location.hash.replace('#', '&action=')
    );

    var isWindows =
        window.navigator.platform.match(/Win/) && !bowser.windowsphone
            ? true
            : false;
    var isWindows8AndUp = window.navigator.userAgent.match(
        /(Windows 10.0|Windows NT 10.0|Windows 8.1|Windows NT 6.3|Windows 8|Windows NT 6.2)/
    )
        ? true
        : false;
    var inApp = Cookies.get('omcbrowse') != undefined;

    if (isWindows && isWindows8AndUp && inApp) {
        var elems = $(
            'li.read-sample a, a.sample, a.sample-circle-icon, a.sample-button'
        );
        elems.each(function () {
            if ($(this).attr('data-href')) {
                $(this)
                    .attr('data-metro', 'true')
                    .attr('href', $(this).attr('data-href'))
                    .attr('target', '_blank')
                    .removeAttr('data-reveal-id');
            }
        });
    }

    if (
        $('.more-actions-drop:not(.list-view)')
            .first()
            .find('.js-contextLink')
            .text().length > 105
    ) {
        $('.more-actions-drop').addClass('is-wide');
    }

    // LHTNG-1431,1432,1804 - adding sort options to holds, wishlist, and rated titles
    // I thought about creating a separate script file account-related stuff rather
    // then shoving this in here, but etung encouraged me to use this catch-all file since
    // the account area is going to be redone in the near future.
    var sortByElem = $('.js-accountSortByOptions');
    sortByElem.on('change', function () {
        window.replaceQueryParam('sortBy', $(this).val());
    });
});

/**
 * Close a modal when the cancel button is clicked
 */
$('[data-cancel]').on('click', function () {
    $(this).foundation('reveal', 'close');
});

window.scrollAmt = 0;

/**
 * Hide carousel arrows when reaching the end of a carousel
 */
$('.carousel.loaded').on('scroll', function (e) {
    hideArrowsOnEndOfScroll(e, this);
});

/**
 * Reset scrollAmt to detect end of new carousel
 */
$('.carousel.loaded').on({
    mouseleave: function () {
        window.scrollAmt = 0;
    },
});

$('.carousel.loaded').each(function () {
    hideCarouselArrows(this);
});

if (!bowser.mobile && !bowser.tablet) {
    $('.carousel.loaded').each(function () {
        Ps.initialize($(this)[0], { suppressScrollY: true });
    });
}

$('i[data-scroll="right"]').on('click', function () {
    var elem = $(this)
        .siblings('.js-carousel')
        .children('div[data-scroll-container]');
    scrollCarousel('right', elem);
});

$('i[data-scroll="left"]').on('click', function () {
    var elem = $(this)
        .siblings('.js-carousel')
        .children('div[data-scroll-container]');
    scrollCarousel('left', elem);
});

/**
 * Actions to perform when a dropdown is closed
 */
$('.drop').on('closed.fndtn.dropdown', function () {
    $(this).find('a').attr('tabindex', '-1');
    $(this).find('h2').attr('tabindex', '-1');
    var elem = $('li[data-dropdown="' + $(this).attr('id') + '"] > a');
    elem.attr('aria-expanded', 'false');
});

$('[data-last-link]').on('focusout', function () {
    var nextItem = $(this).parents('.content.f-dropdown').next('li');
    nextItem.children('a').focus();
});

$('ul.drop.content.f-dropdown')
    .on('keydown', function (e) {
        arrowTabMenu(e);
    })
    .on('keydown', '*', function (e) {
        arrowTabMenu(e);
    });

$('ul#dropAccount')
    .on('keydown', function (e) {
        arrowTabAccountMenu(e);
    })
    .on('keydown', '*', function (e) {
        arrowTabAccountMenu(e);
    });

// Fix for select elements in IOS app view
$(document).ready(function () {
    $(document).on('popupbeforeposition', function () {
        $.mobile.hashListeningEnabled = false;
    });
    $(document).on('popupafterclose', function () {
        $.mobile.hashListeningEnabled = true;
    });
});

$('#link-card-modal').on('opened.fndtn.reveal', function () {
    var button = $('#add-card-button');
    if (button.attr('data-fwd-action')) {
        button.attr('href', button.attr('data-fwd-action'));
        button.removeAttr('data-fwd-action');
    }
});

$(window).resize(function () {
    clearTimeout(this.id);
    this.id = setTimeout(function () {}, 500); // Do not run constantly on resize
});

// Detect if user is scrolling or not
var scrolling = false;
$('body')
    .on('touchmove', function () {
        scrolling = true;
    })
    .on('touchend', function () {
        scrolling = false;
    });

/**
 * Used to automatically redirect links in collections menu on iOS
 * Sometimes they just like to hover instead of click. Because Apple.
 */
$('.js-link').on('click touchend', function (e) {
    if (scrolling) {
        scrolling = false;
        return;
    }
    window.location = $(this).attr('href');
});

// Custom jquery filter to select focusable elements
// Wasn't sure where else to put this for the time being
$.extend($.expr[':'], {
    focusable: function (el, index, selector) {
        return $(el).is('a, button, :input, [tabindex]');
    },
});

try {
    window.console &&
        window.console.log &&
        window.console.log(
            'Hi there! This site is powered by OverDrive and our vision is a world enlightened by reading. Maybe a curious cat like you can help https://company.overdrive.com/company/careers/open-positions/'
        );
} catch (e) {}
