const { text } = window;

const _getAccessibilitySettings = () => {
    const { highContrast, dyslexic } = window.OverDrive;
    const accessibilitySettings = [];
    if (highContrast) {
        accessibilitySettings.push('high-contrast=1');
    }
    if (dyslexic) {
        accessibilitySettings.push('dyslexic=1');
    }

    if (accessibilitySettings.length === 0) {
        return '';
    }

    return `?${accessibilitySettings.join('&')}`;
};

export default function () {
    const accessibilitySettings = _getAccessibilitySettings();

    return `
    <div>${text('gdpr.settingsPanel.summary')}</div>
    <div class="optionContainer">
        <i class="icon-check"></i>
        <h3 class="requiredHeading">${text('gdpr.settingsPanel.requiredHeading')}</h3>
        <span class="labelSubtext requiredSubtext">${text('gdpr.settingsPanel.requiredBody')}</span>
    </div>
    <div class="optionContainer">
        <span class="perfCookieCheckbox"></span>
        <span class="labelSubtext">${text('gdpr.settingsPanel.performanceBody')}</span>
    </div>
    <div class="optionContainer">
        <span class="analyticsCookieCheckbox"></span>
        <span class="labelSubtext">${text('gdpr.settingsPanel.analyticsBody')}</span>
    </div>
    <div class="divider"></div>
    <div class="addendum">
        ${text('gdpr.settingsPanel.addendum', {
        cookiePolicyLink: `<a target="blank" href="https://company.cdn.overdrive.com/policies/cookie-policy.htm${accessibilitySettings}">${text('gdpr.cookiePolicy')}</a>`,
        privacyPolicyLink: `<a target="blank" href="https://company.cdn.overdrive.com/policies/privacy-policy.htm${accessibilitySettings}">${text('footerPrivacyPolicy')}</a>`
    })}
    </div>


    <button class="confirm button radius margin-small secondary right">${text('gdpr.settingsPanel.confirm')}</button>

`; }
