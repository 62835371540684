import svgLoader        from '../../util/svgLoader';
import NotificationItem from './NotificationsItem';
import keys             from '../../util/keycodes';
const { text, url } = window;

export default class NotificationDrawer extends Backbone.View{

    constructor(){
        super(...arguments);
        this.$triggerEl = $('.js-notificationsButton');
        this.isOpen = false;
        this.PAGE_SIZE = 5;
        this.totalNotifications = 0;
        this.pageNum = 0;
        this.notifications = [];

        this.renderShell();
        this.loadPage();

        this.$el.on('click', (e) => {
            // clicks inside the container are trapped so they can't trigger the close handler.
            e.stopPropagation();
        });

        $('body').on('click', (e) => this.tryClose(e));
        this.$triggerEl.on('click', (e) => this.toggle(e));

        this.$el.on('keyup', (e) => {
            if(e.which === keys.ESC) {
                this.tryClose(e)
            }
        });
    }

    close(){
        this.$el.detach();
        this.isOpen = false;
    }

    tryClose(e) {
        if (this.isOpen && e && !e.originalEvent.isDrawerOpenClick){
            this.close();
        }
    }

    open(e) {
        if (!this.isOpen) {
            e.originalEvent.isDrawerOpenClick = true;
            // There are two potential click targets: one for the mobile nav and one for the desktop nav
            $(e.currentTarget).siblings('.Notifications-Dropdown').append(this.$el);
            this.isOpen = true;
            $('.icon-notification').removeClass('ring');
            svgLoader();

            this.$el.find(':focusable:visible').first().focus();

            this.$el.on('keydown', (ev) => {
                if (ev.which === keys.TAB) {
                    this.keepFocus(ev);
                }
            });
        }
    }

    toggle(e) {
        if (this.isOpen){
            this.tryClose(e);
            e.stopPropagation();
        } else {
            this.open(e);
        }
    }

    keepFocus(e) {
        const activeElem = $(document.activeElement);
        const firstFocusable = this.$('.Notification-item')[0] || this.$el.find(':focusable:visible').first();
        const lastFocusable = this.$el.find(':focusable:visible:tabbable')[this.$el.find(':focusable:visible:tabbable').length - 1];

        if (activeElem.is(firstFocusable) && e.which === keys.TAB && e.shiftKey) {
            e.preventDefault();
            lastFocusable.focus();
        } else if ($(activeElem).is(lastFocusable) && e.which === keys.TAB && !e.shiftKey) {
            e.preventDefault();
            firstFocusable.focus();
        }
    }

    startSpinner(target){
        target.append(this.$spinner);
    }

    stopSpinner(){
        this.$spinner.detach();
    }

    startNagging(resultCount){
        $('.notificationDotContainer').css('display', 'block');
        $('.notificationDotContainer.mobile').css('display', 'inline-block');
        $('.js-notificationsButton').attr('aria-label', text('notifications.activeNotificationsLabel', {num: resultCount}));
        $('.js-notificationsButton').attr('role', 'button alert');
        $('.icon-notification').addClass('ring');
    }

    renderShell() {
        this.$el.empty();
        this.$el.append(`<div class='NotificationsCaret white'></div>
                        <div class="NotificationsContainer">
                            <div class="spinnerBox">
                                <div class='SpinnerContainer'>
                                    <div class='spinner'></div>
                                </div>
                            </div>
                        </div>`);
        this.$spinner = this.$el.find('.spinnerBox');
        this.$mainContainer = this.$el.find('.NotificationsContainer');
        this.$caret = this.$el.find('.NotificationsCaret');
    }

    get hasMorePages(){
        return this.pageNum < (this.numPages - 1);
    }

    loadPage(){
        this.getNotifications().then((res) => {

            if (this.pageNum === 0){
                this.totalNotifications = res.totalResultCount;
                this.numPages = Math.ceil(this.totalNotifications / this.PAGE_SIZE);
            }

            // It's possible for invalid notiifications to be pruned from the response before it reaches the client.
            // There is an edge case wehre an entire page of results may be empty because all of the items were pruned, but
            // there are still more pages available.  In this case we will automatically advance to the next page without further
            // user interaction.
            if (res && res.items && res.items.length === 0 && this.hasMorePages){
                this.pageNum += 1;
                return this.loadPage();
            }

            this.stopSpinner();
            if (res && typeof res.items !== 'undefined'){
                let newNotifications = [];
                res.items.forEach((n) => {
                    let item =  new NotificationItem(n);
                    item.registerDismissCallback(() => this.notificationDismissed());
                    newNotifications.push(item);
                });
                this.notifications = this.notifications.concat(newNotifications);
                this.renderPage(newNotifications);
            }


        }).catch((error) => {
            this.stopSpinner();
            window.Logger.log('error', 'Failed to show notifications', {message: error.statusText || error});
            if (this.pageNum === 0){
                this.$mainContainer.empty().append($(`<div class='No-Notifications'><p>${text('notifications.fetchingError', {link: this.getContactSupportLink()})}</p></div>`));
            } else {
                this.$loadMoreBox.empty().append(`<button tabindex='0' class='Notifications-loadMore'>${text('notifications.loadMoreError')} ${text('notifications.loadMoreTryAgain')}</button>`);
                this.$loadMoreBox.find('.Notifications-loadMore').on('click', (e) => this.loadMoreClick(e));
            }
        });
    }

    getNotifications() {
        return new Promise((resolve, reject) =>
        $.ajax({
            method: 'GET',
            url: window.url(window.routes.rest.patronNotifications.get),
            data: {
                pageNumber: this.pageNum,
                pageSize: this.PAGE_SIZE
            },
            timeout: 10000
        })
        .done((response) => resolve(response))
        .fail((xhr, status, err) => reject(err)));
    }

    renderPage(notifications){
        if (this.pageNum === 0 && (!notifications || notifications.length === 0)){
            this.displayNoNotificationsMessage();
        }
        else if (this.notifications.length > 0) {
            if (this.pageNum === 0) {
                this.$mainContainer.append(`<div class='NotificationsCountItem'></div>
                                            <div class='NotificationsListContainer'>
                                                <ul class='NotificationsList'></ul>
                                            </div>`);

                this.$notificationContainer = this.$mainContainer.find('.NotificationsList');
                this.$countContainer = this.$mainContainer.find('.NotificationsCountItem');
                if (this.totalNotifications > 0){
                    this.startNagging(this.totalNotifications);
                }
            }

            this.updateCount();
            this.updateCaret();

            if (this.$loadMoreBox){
                this.$loadMoreBox.remove();
            }

            notifications.forEach((n) => {
                this.$notificationContainer.append(n.render());
            });

            if (this.hasMorePages){
                this.$loadMoreBox = $(`<li class='loadMoreItem'>
                                            <button tabindex='0' class='Notifications-loadMore'>${text('notifications.loadMore')}</button>
                                        </li>`);
                this.$notificationContainer.append(this.$loadMoreBox);
                this.$loadMoreBox.find('.Notifications-loadMore').on('click', (e) => this.loadMoreClick(e));
            }

            if (this.pageNum !== 0) {
                $('.Notification-item')[this.pageNum * this.PAGE_SIZE].focus();
            }

            this.pageNum += 1;
        }
    }

    displayNoNotificationsMessage() {
        this.$mainContainer.empty().append(`<div class='No-Notifications' tabindex='0'>
                        <div class='No-NotificationImageContainer svg dynamic-loader' data-src='no-notifications-image.svg'></div>
                            <h5 class='No-Notifications-Header'>${text('notifications.noNotificationsLabel')}</h5>
                            <p>${text('notifications.noNotifications')}</p>
                        </div>`);
    }

    loadMoreClick() {
        this.$loadMoreBox.empty();
        this.startSpinner(this.$loadMoreBox);
        this.loadPage();
    }

    updateCaret(){
        if (this.notifications.length > 0){
            this.$caret.removeClass('white');
        } else if (!this.$caret.hasClass('white')){
            this.$caret.addClass('white');
        }
    }

    updateCount(){
        this.$countContainer.empty();
        this.$countContainer.append(`<h2>${text('notifications.notificationCount', {num: this.notifications.length, total : this.totalNotifications})}</h2>`);
    }

    notificationDismissed(){
        this.totalNotifications -= 1;
        this.notifications = this.notifications.filter((x) => !x.isDismissed);

        if (this.totalNotifications < 1) {
            this.displayNoNotificationsMessage();
        }

        this.updateCount();
    }

    getContactSupportLink(){
        let link = '';
        if (window.OverDrive.librarySupportUrl !== ''){
            link = window.OverDrive.librarySupportUrl;
        } else if (window.OverDrive.librarySupportEmail !== ''){
            link = window.OverDrive.librarySupportEmail;
        } else {
            link = url(window.routes.supportMembers);
        }
        return `<a class='primary-color' href='${link}' target='_blank'>${text('contactSupport').toLowerCase()}</a>`;
    }
}
