import getQueryParams from '../url/getQueryParams.js';
import {removeQueryParam} from '../query.js';
import {recommend} from '../forwardedActions.js';
import selectStarRating from './selectStarRating';
import {html} from '../../../../lib/i18n/browser';
const openHoldConflictModal = './openHoldConflictModal.js';
const handleForwardActionHold = './handleForwardActionHold.js';

const processInstantForwardActions = function(stub) {
    const theStub = stub || window.location.pathname + (window.location.search ? window.location.search : '');
    let action = getQueryParams(window.location.search).action;
    const once = $('body').attr('data-action-processed');
    const qs = removeQueryParam(window.location.search, 'action');

    if (action && action.indexOf('recommend~') === 0) {
        recommend(qs, action, window.globalEvents);
        return;
    }

    if (action && !once && action.indexOf('~') > -1 && $('body').attr('no-linked-cards') === 'false') {
        history.replaceState(null, null, window.location.pathname + qs);

        action = action.split('~');
        const titleId = action[1];
        const actionElem = $(`[data-action="wishlist-add"][data-media-id="${titleId}"]`);
        const titleElem = $(`div.wishlist[data-id="${titleId}"] a`);
        const rating = parseInt(action[2], 10);
        const actionButton = $(`[data-action][data-media-id="${titleId}"]`).first(); // Action button
        const titleCardLink = $(`a.primary-action[data-media-id="${titleId}"], .TitleAction-button[data-media-id="${titleId}"]`).first(); // Link on bottom of title card
        let elem = null;
        let possibleAction = ''; // the action currently attached to the button

        switch (action[0]) {
            case 'wish':
            case 'wishlist-add':
            case 'wishlist-remove':

                // If already added ot the wishlist break.
                if ($(`[data-action="wishlist-remove"][data-media-id="${titleId}"]`).length || $(`div.wishlist[data-id="${titleId}"] i.icon-wish-list-added`).length) {
                    break;
                }

                // Check if item exists on page if it does: click element else: take action manually.
                if (actionElem.length) {
                    // Action button exists
                    actionElem.click();
                } else if (titleElem.length) {
                    // Grid / carousel item exists
                    titleElem.click();
                } else {
                    // Fallback: get media info
                    ajax.wishListAdd(titleId, $('body'));
                    $.ajax({
                        url: window.url(window.routes.mediaInfoId, {id: titleId}),
                        type: 'GET'
                    });
                }
                break;
            case 'star':
                // Needs to happen last in the queue after media status
                $('body').on('userStarRatingSet', () => {
                    selectStarRating($(`.star-ratings-container[data-media-id="${titleId}"]`), rating);
                });
                break;
            default: // borrow, hold, recommend to library

                if (actionButton.length && (actionButton.is(':visible') || Cookies.get('media-view-preference') === 'list')) {
                    elem = actionButton;
                    possibleAction = elem.attr('data-action');
                    if (possibleAction === 'borrow-simple') {
                        possibleAction = 'borrow';
                    }
                } else if (titleCardLink.length) {
                    elem = titleCardLink;
                    if (elem.attr('onclick') && elem.attr('onclick').toLowerCase().indexOf('borrow') > -1 || elem.hasClass('js-borrow')) {
                        possibleAction = 'borrow';
                    } else if (elem.attr('onclick') && elem.attr('onclick').toLowerCase().indexOf('hold') > -1 || elem.hasClass('js-hold')) {
                        possibleAction = 'hold';
                    }
                }

                if (elem) {
                    if (action[0] === possibleAction || action[0] === 'nook') {
                        // the desired action button is visible, click it
                        elem.click();
                    } else if (possibleAction === 'hold') {
                        openHoldConflictModal(titleId, elem);
                    }
                } else {
                    // Fallback: Get media details
                    $.ajax({
                        url: window.url(window.routes.mediaInfoId, {id: titleId}),
                        type: 'GET',
                        success(data) {
                            if (action[0] === 'borrow' || action[0] === 'borrow-simple') {
                                ajax.borrow(titleId, false, $('body'));
                                $(document).on('borrowSuccess', (e, eventElem, expDate) => {
                                    // eBook should be properly capitalized, but other types should be lowercase
                                    const type = data.type.id === 'ebook' ? data.type.name : data.type.id;
                                    ajax.borrowSuccessToast(expDate, type);
                                }).on('borrowFailure', (e, innerElem, response) => {
                                    const parsedResponse = JSON.parse(response.jqXHR.responseText);

                                    // Only execute if the title can be placed on hold and the target was the body
                                    if (parsedResponse.errorCode === 'TitleNoLongerAvailable' && innerElem.context.tagName === 'BODY') {
                                        const fakeElem = $('<a>', {
                                            'data-title': data.title,
                                            'data-type': data.type.id,
                                            'data-artwork': data.covers.cover300Wide.href == null ? 'img/no-cover_en_US.jpg' : data.covers.cover300Wide.href,
                                            'data-prerelease': html('date-full', {d: data.estimatedReleaseDate})
                                        });
                                        openHoldConflictModal(titleId, fakeElem);
                                    } else if (parsedResponse.errorCode) {
                                        let href = '';
                                        let linkText = '';
                                        if (parsedResponse.errorCode === 'PatronExceededCheckoutLimit' || parsedResponse.errorCode === 'LoanAlreadyExists') {
                                            href = window.url(window.routes.accountLoans);
                                            linkText = window.html('loans');
                                        } else if (parsedResponse.errorCode === 'PatronExceededChurningLimit' || parsedResponse.errorCode === 'PatronTypeRestrictedFromTitle') {
                                            href = window.url(window.routes.supportMembers);
                                            linkText = window.html('pleaseContactSupport');
                                        }

                                        if (toaster)
                                        {
                                            const link = `<a class="bold" href="${href}" tabindex="0">${linkText}</a>`;
                                            toaster.push(`<strong>${window.html('genericError')}</strong> ${window.text(`error.${parsedResponse.errorCode}`, {link})}`, 'error');
                                        }
                                    }
                                });
                            } else if (action[0] === 'hold') {
                                $.when(ajax.getAvailability(titleId)).done((innerData) => {
                                    if (innerData.availableCopies > 0) {
                                        const fakeElem = $('<a>', {
                                            'data-title': innerData.title,
                                            'data-type': innerData.type.id,
                                            'data-artwork': innerData.covers.cover300Wide.href == null ? 'img/no-cover_en_US.jpg' : innerData.covers.cover300Wide.href,
                                            'data-prerelease': html('date-full', {d: innerData.estimatedReleaseDate})
                                        });
                                    } else {
                                        handleForwardActionHold(innerData, titleId);
                                    }
                                }).fail(() => {
                                    handleForwardActionHold(data, titleId);
                                });
                            }
                        }
                    });
                }
                break;
        }

        $('body').attr('data-action-processed', true);
    } else if (action && !once && $('body').attr('no-linked-cards') === 'true') {
        action = action.split('~');
        const titleId = action[1];

        // TODO: This logic is completely buggy and wrong
        const forwardUrl = theStub.replace(encodeURIComponent('?no-linked-card=true'), '').replace(/(&|\?)no-linked-card(=true|\=false)/, '');
        const cardLinkUrl = window.url(window.routes.accountStartCardLink, {forward: forwardUrl, action: [action[0], titleId].join('~')});

        $('#add-card-button').attr('data-fwd-action', cardLinkUrl);
    }
};

export default processInstantForwardActions;
