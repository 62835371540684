import { Modal } from '../../common/modal';
import DownloadWarningTemplate from '../templates/DownloadWarningModal.html';

let featureManager = window.featureManager;

const { _locale, supportArticles } = window.OverDrive;
const { Cookies } = window;

const isIpadInApp = !!(
    featureManager.isEnabled('stop-treating-ipad-like-mac') &&
    window.navigator.userAgent.match(/App Store OverDrive Media Console/)
);
const isMac = !!(
    window.navigator.userAgent.match(/Mac/) &&
    !bowser.ios &&
    !isIpadInApp
);
const isWindows =
    !!(window.navigator.userAgent.match(/Win/) && !bowser.windowsphone) &&
    !window.OverDrive.isTolinoDevice;
const isWindows8AndUp = !!window.navigator.userAgent.match(
    /(Windows 10.0|Windows NT 10.0|Windows 8.1|Windows NT 6.3|Windows 8|Windows NT 6.2)/
);
const inApp = Cookies.get('omcbrowse') !== undefined;
const isWindowsPhone10AndUp = !!(
    bowser.windowsphone && parseInt(bowser.osversion, 10) >= 10.0
);
const isWindowsPhone8 = !!(
    bowser.windowsphone && parseInt(bowser.osversion, 10) < 10.0
);

export default class DownloadWarningModal extends Backbone.View {
    initialize(el) {
        let format = el.data('format-id');
        let mediaId = el.data('mediaId');

        let warning = this.getWarning(format);
        let downloadUrl = window.url(window.routes.mediaDownload, {
            format,
            mediaId,
        });

        if (warning !== '' && !this.isWarningCookieSet(warning)) {
            let viewData = { message: this.warningText(warning), downloadUrl };

            const content = $(DownloadWarningTemplate(viewData));

            this.modal = new Modal({
                title: html('downloadWarningModal.heading'),
                content: content,
            });

            this.modal.show();

            this.modal.$el.find('.cancel').on('click', () => {
                this.modal.close();
            });
            this.modal.$el.find('.confirm').on('click', () => {
                this.setWarningCookie(warning);
                this.modal.close();
            });
        } else if (format === 'ebook-media-do') {
            if (inApp && featureManager.isEnabled('mediado-safari-fix')) {
                window.location.href = downloadUrl;
            } else {
                window.open(downloadUrl, '_blank');
            }
        } else {
            // if no warning needs displayed, download title
            window.location.href = downloadUrl;
        }
    }

    warningText(warning) {
        switch (warning) {
            case 'ODapp':
                return html('downloadWarningModal.required', {
                    link:
                        '<a href="http://app.overdrive.com/" target="_blank" class="modal-link" aria-label="' +
                        html('overdriveApp') +
                        '" role="link">' +
                        html('overdriveApp') +
                        '</a>',
                });
            case 'ADE':
                return html('downloadWarningModal.required', {
                    link:
                        '<a href="http://www.overdrive.com/partners/redirects.aspx?product=adobede"' +
                        ' target="_blank" class="modal-link" aria-label="Adobe Digital Editions" role="link">Adobe Digital Editions</a>',
                });
            case 'ODapp-epub':
                return html('downloadWarningModal.epubRequired', {
                    link:
                        '<a href="http://app.overdrive.com/" target="_blank" class="modal-link" aria-label="' +
                        html('overdriveApp') +
                        '" role="link">' +
                        html('overdriveApp') +
                        '</a>',
                });
            case 'video':
                return html('downloadWarningModal.youMayNeedOdApp', {
                    link:
                        '<a href="http://app.overdrive.com/" target="_blank" class="modal-link" aria-label="' +
                        html('overdriveApp') +
                        '" role="link">' +
                        html('overdriveApp') +
                        '</a>',
                });
            case 'msedge':
                return html('downloadWarningModal.mayWorkOnDiffBrowser', {
                    link1:
                        '<a href="https://www.microsoft.com/en-us/download/internet-explorer.aspx" target="_blank" class="modal-link" aria-label="' +
                        html('downloadWarningModal.downloadBrowser', {
                            browser: 'Internet Explorer',
                        }) +
                        '" role="link">Internet Explorer</a>',
                    link2:
                        '<a href="http://www.google.com/chrome/" target="_blank" class="modal-link" aria-label="' +
                        html('downloadWarningModal.downloadBrowser', {
                            browser: 'Chrome',
                        }) +
                        '" role="link">Chrome</a>',
                });
            case 'ODread':
                return html('downloadWarningModal.messageForODRead');
            case 'Libby':
                return html('downloadWarningModal.libbyRequired', {
                    link:
                        '<a href= "https://meet.libbyapp.com/" target="_blank" class="modal-link" aria-label="' +
                        html('libby.libbyApp') +
                        '" role="link">' +
                        html('libby.libbyApp') +
                        '</a>',
                });
            case 'notSupported':
                return html('downloadWarningModal.notSupported', {
                    link:
                        '<a href="' +
                        window.supportUrl(
                            _locale,
                            supportArticles.noWindowsPhoneSupport
                        ) +
                        '" target="_blank" class="modal-link" aria-label="' +
                        html('narratedModal.learnMore') +
                        '" role="link">' +
                        html('narratedModal.learnMore') +
                        '</a>',
                });
        }
    }

    getWarning(format) {
        let warning = '';
        if (format === 'ebook-epub-adobe') {
            if (isMac) {
                warning = 'ADE';
            } else if (isWindows && !inApp) {
                warning = 'ADE';
            } else if (
                (bowser.ios ||
                    (bowser.android && !window.OverDrive.isTolinoDevice)) &&
                !inApp
            ) {
                warning = 'ODapp';
            } else if (isWindowsPhone10AndUp && !inApp) {
                warning = 'Libby';
            } else if (isWindowsPhone8 && !inApp) {
                warning = 'notSupported';
            }
        } else if (
            format === 'ebook-pdf-adobe' &&
            (isMac || (isWindows && !inApp))
        ) {
            warning = 'ADE';
        } else if (format === 'ebook-epub-open') {
            if (isMac) {
                warning = 'ADE';
            } else if (isWindows && !inApp) {
                warning = 'ADE';
            } else if (
                (bowser.ios ||
                    (bowser.android && !window.OverDrive.isTolinoDevice)) &&
                !inApp
            ) {
                warning = 'ODapp-epub';
            } else if (isWindowsPhone10AndUp && !inApp) {
                warning = 'Libby';
            } else if (isWindowsPhone8 && !inApp) {
                warning = 'notSupported';
            }
        } else if (
            format === 'audiobook-mp3' &&
            !(featureManager.isEnabled('odapp-sunset') && !inApp)
        ) {
            if (
                ((bowser.ios || bowser.android) && !inApp) ||
                isMac ||
                (isWindows && !inApp)
            ) {
                warning = 'ODapp';
            } else if (isWindowsPhone10AndUp && !inApp) {
                warning = 'Libby';
            } else if (isWindowsPhone8 && !inApp) {
                warning = 'notSupported';
            }
        }
        return warning;
    }

    isWarningCookieSet(warning) {
        const cookie = Cookies.get('no-device-warning');
        if (cookie === undefined) {
            return false;
        } else {
            let arr = cookie.split(';');
            return arr.indexOf(warning) > -1;
        }
    }

    setWarningCookie(warning) {
        let cookie = Cookies.get('no-device-warning');
        if (cookie === undefined) {
            Cookies.set('no-device-warning', warning + ';', {
                path: '/',
                expires: 365,
                overwrite: true,
            });
        } else {
            Cookies.set('no-device-warning', cookie + warning + ';', {
                path: '/',
                expires: 365,
                overwrite: true,
            });
        }
    }
}
