export default ({title, cover, lendingPeriods, isRenewal, showRemoveHoldOption, removingHold, isLuckyDayRestricted, isLuckyDayHoldable, luckyDayLendingPeriod}) => `
<div class='content'>
    <div class='row'>
        <div class='small-5 columns cover'>
            ${cover.$el.html()}
        </div>
        <div class='small-7 columns data'>
            <div class='row'>
                <div class='small-12 columns title'>${title.get('title')}</div>
                ${title.get('series') && !title.isMagazine ? `<div class='small-12 columns'>${title.get('series')}</div>` : ''}
                ${title.isMagazine && title.get('edition') ? `<div class='small-12 columns'>${title.get('edition')}</div>` : ''}
                <div class="title-format-badge contrast u-allCaps"><i class="icon-${title.get('type').id}"></i>${window.html(title.get('type').id)}</div>
                <div class='small-12 columns lending-period'>
                    ${lendingPeriods.length > 1 ?
                        window.html(isRenewal ? 'renewal.renewForTextHtml' : 'borrowForTextHtml', {text: `
                        <div class='lending-period-dropdown'><select class='lending-period-select' data-open="false">
                            ${lendingPeriods.map((lendingPeriod) =>
                                `<option value='${lendingPeriod.cid}' ${lendingPeriod.get('isPreferredLendingPeriod') ? 'selected' : ''}>
                                    ${window.html('accountLoans.expiresDays', {time: lendingPeriod.period.days})}
                                </option>`
                            )}
                        </select><i class='icon-down-arrow'></i></div>`})
                     : isLuckyDayRestricted ?
                        isLuckyDayHoldable ?
                            window.html('luckyDay.restrictedAllowHolds', {days: luckyDayLendingPeriod})
                            : title.get('isOnHold') ?
                                  window.html('luckyDay.restrictedOnHold', {days: luckyDayLendingPeriod})
                                : window.html('luckyDay.restricted', {days: luckyDayLendingPeriod})
                     : lendingPeriods.first().label }
                </div>
            </div>
        </div>
    </div>
    <div class='row buttons'>
    ${ showRemoveHoldOption ?
        `<div class='button-column onHoldRadioContainer'>
            <div class='holdOption removeHold'></div>
            <div class='holdOption stayOnHold'></div>
        </div>`
    : ''}
    ${removingHold ? `<div class="removingHoldWarning">${window.html('luckyDay.yourHoldRemoved')}</div>` : ''}
    <div class='small-12 columns button-column'>
        <button type='button' ${isLuckyDayRestricted ? '' : `aria-label='${window.html(isRenewal ? 'renewal.renewTitle' : 'borrowTitle', {title: title.get('title')})}'`} class='button radius secondary contrast u-allCaps borrow-button'>
            ${isRenewal
                ? window.html('renewal.renew')
                : isLuckyDayRestricted
                    ? window.html('luckyDay.borrowFor', {days: luckyDayLendingPeriod})
                    : window.html('borrow')}
        </button>
    </div>
    ${ isLuckyDayHoldable ?
        `<div class='small-12 columns button-column'>
            <button type='button' class='button radius outline u-allCaps hold-button'>
                ${window.html('placeHold')}
            </button>
        </div>`
    : ''}
    <div class='small-12 columns button-column'>
        <button type='button' aria-label='${window.html('cancel')}' class='button radius outline gray u-allCaps cancel-button'>
            ${window.html('cancel')}
        </button>
    </div>
    </div>
<div>
`;


