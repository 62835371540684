const Backbone  = window.Backbone;

import { Modal } from '../../common/modal';
import TitleCollectionView from '../TitleCollectionView';
import TitleCollection from '../models/TitleCollection';
import PatronMediaItemsModel from '../../../models/patron_media_items';
import TitleCard from '../TitleCard';

const fs = require('fs');
const path = require('path');
const RtlSuccessModalTemplate = fs.readFileSync(path.join(__dirname, '../templates/RTLSuccess.html'), 'utf8');

const RtlSuccessModal = Backbone.View.extend({
    template: _.template(RtlSuccessModalTemplate),

    initialize(bus, data) {
        this.titleModel = data.titleModel;
        this.titleCollection = new TitleCollection();
        this.bus = bus;

        this.$panel = $(this.template({
            relatedMediaLink: window.url(window.routes.mediaRelatedId, { id: this.titleModel.id }),
            email: data.email,
            message: 'recommendSuccessModal.successMessageAdd'
        }));

        this.$relatedTitles = this.$panel.find('.js-recommend-success-title-container');

        this._renderRelatedTitles()
            .then(() => this._renderModal());
    },

    _renderRelatedTitles() {
        this.titleCollection.url = `${window.url(window.routes.rest.media.related, {id: this.titleModel.id})}?max=2`;
        this.titleCollection.parse = (response) => {
            let collectionTitles = response.titles;
            if (window.featureManager.isEnabled('only-show-owned-content') && response.titles) {
                collectionTitles = response.titles.filter((x) => x.isOwned === true);
            }
            return _.values(collectionTitles);
        };

        return this.titleCollection.fetch()
            .done((res) => {
                let relTitles = res.titles;
                const patronMediaItemsModel = new PatronMediaItemsModel({relTitles});

                this.titleCollectionView = new TitleCollectionView({
                    el: this.$relatedTitles,
                    bus: this.bus,
                    collection: this.titleCollection,
                    patronMediaItemsModel,
                    View: TitleCard,
                    coverWidths: {mobile: 184, tablet: 220, desktop: 220}
                });

                this.titleCollectionView.$el.find('.js-titleCard').addClass('RecommendSuccessModal-item small-6 columns');

                if (window.featureManager.isEnabled('recommend-strategy-ga'))
                {
                    this.bus.trigger('title:recommended', {
                        titleModel : this.titleModel,
                        context    : {strategy: res.strategy}
                    });
                }
            }).fail(() => `Failed to retrieve related titles for: ${this.titleModel.id}`);
    },

    _renderModal() {
        let modalContents = {
            title: window.html('recommendSuccessModal.heading'),
            classes: ['small', 'RecommendSuccessModal', 'js-recommendation-success-modal'],
            content: this.$panel,
            customButtons: true
        };

        this.modal = new Modal(modalContents);
    }
});

module.exports = RtlSuccessModal;
