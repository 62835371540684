import template from './SuspendHoldModalTemplate.html';
import {Modal} from '../common/modal';
import RemoveSuspendedHoldModal from '../RemoveSuspendedHoldModal/RemoveSuspendHoldModal';
import SuspendHoldSuccessModal from '../SuspendHoldSuccessModal/SuspendHoldSuccessModal';
import addDays from '../../util/dates/addDays';
const { html } = window;

// There are 3 possible states for this which affect copy:
// Suspended and never delivered --> Standard Suspend Hold modal
// Suspended and has been delievered before --> Gets some dynamic date updates and a new label
// Available --> Same as the option above (with a new label), but also comes with a new dropdown option

export default class SuspendHoldModal extends Backbone.View {
    constructor(model, redelivery, isNotification = false) {
        super(...arguments);
        this.model = model;
        this.redelivery = redelivery;
        this.isAvailable = model.get("isAvailable");
        // Checks for if a suspended title has become available to the user at any point
        this.deliveredPreviously = model.get("suspensionFlag") && (model.get("isAvailable") || (model.get("redeliveriesAutomatedCount") > 0 || model.get("redeliveriesRequestedCount") > 0));
        this.isNotification = isNotification;
        this.render();
    }

    render(){
        let modalContents = {
            title: this._getTitle(),
            autoShow: false,
            classes: ['small', 'suspension-modal', 'version-two'],
            content: template(this.model.attributes, "", "", "", this.isAvailable, this.deliveredPreviously, this.model.get("suspensionFlag"))
        };

        if(window.featureManager.isEnabled('suspend-modal-data-adjustment')){
            let cover = this.model.getCover(150);
            modalContents.content = template(null, cover && cover.href, this.model.get('title'), this.model.get('firstCreatorName'),
                                             this.isAvailable, this.deliveredPreviously, this.model.get("suspensionFlag"));
        }

        this.modal = new Modal(modalContents);
        this._renderText(this.modal.$el);
        this.attachEvents(this.modal.$el);
        this.modal.show();
    }

    attachEvents(modal){
        modal.find("#suspend-hold").on('click', () => {
            let days = modal.find('#select-suspension').val();
            
            if(days > 0) {
                this._suspendHold(days);
            } else {
                this._removeSuspension(this.model.get('id'));
            }
        });

        modal.find(".remove-suspension-button").on('click', () => {
            new RemoveSuspendedHoldModal(this.model, this.isNotification);
        });

        modal.find(".cancel").on('click', () => {
            modal.find('.close-reveal-modal').click();
        });

        modal.find("#select-suspension").on('change', () => {
            this._renderText(modal);
        });
    }

    _getTitle() {
        if(this.isAvailable) {
            return html('suspendHoldModal.availableTitle');
        } else if (this.deliveredPreviously) {
            return html('suspendHoldModal.previouslyDeliveredTitle');
        } else {
            return html('suspendHoldModal.heading');
        }
    }

    _renderText(modal) {
        const description = modal.find('#suspend-description');
        description.empty();

        let days = modal.find('#select-suspension').val();
        let text = '';

        if(days > 0) {
            let date = addDays(days);
            this.isAvailable || this.deliveredPreviously ? 
                text = html('holdRedelivery.suspendHoldText', {expirationDate: html('date-long', {d: date})}) :
                text = html('suspendHoldModal.explanationDynamic', {expirationDate: html('date-long', {d: date})});
        } else {
            text = html('holdRedelivery.suspendHoldRemoveText');
        }

        description.append(text);
    }

    _suspendHold(days) {
        window.suspendHold(this.model.get('id'), days);

        var model = this.model;
        var numberOfDays = days;
        var isRedelivery = this.isAvailable;
        var isSuspended = this.model.get("suspensionFlag");
        var context = { 
            numOfDays : numberOfDays, 
            isRedelivery: isRedelivery,
            isSuspended: isSuspended,
            isNotification: this.isNotification
        };
        window.globalEvents.trigger("title:suspend", {titleModel : model, context});

        if(this.isAvailable || this.deliveredPreviously) {
            new SuspendHoldSuccessModal(this.model, addDays(days));
        } else {
            this.modal.$el.find('.close-reveal-modal').click();
        }
    }

    _removeSuspension = function (mediaId) {
        let promise = window.callToRemoveSuspension(mediaId);
    
        promise.then(() => {
            window.globalEvents.trigger("title:suspend:cancelled", { titleModel: this.model, context: { isNotification: this.isNotification }});
            new SuspendHoldSuccessModal(this.model, 'today');
        }).catch((err) => {
            window.Logger.log('error', 'Error removing hold suspension', {message: err.statusText || err});
        });
    }
}
