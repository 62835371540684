import { Modal } from '../../common/modal';

export default class ImportFromRatedCompleteModal extends Backbone.View {
    titlesImportedTemplate = (importedTitleCount) =>  `<p><span class="icon-check"/>&nbsp;${html('readingHistory.addRatedTitlesSuccess', {count: importedTitleCount})}</p>`

    noTitlesImportedTemplate = () => `
        <p>${html('readingHistory.allRatedTitlesAdded')}</p>
        <p>${window.text('readingHistory.ifYoudLike',
            { link: `<a target = "_blank" href="https://help.overdrive.com/customer/portal/articles/2833477"> ${window.text('readingHistory.manuallyAdd')}</a>`  })}
        </p>`

    noTitlesRatedTemplate = () => `
        <p>${html('readingHistory.noneRated')}</p>
    `;

    constructor(importedTitleCount, patronHasRatedTitles) {
        super();

        this._render(importedTitleCount, patronHasRatedTitles);

        let title = '';
        if (importedTitleCount > 0) {
            title = html('genericSuccess');
        }
        else {
            title = html('readingHistory.addRatedTitlesFailure');
        }

        let modalContents = {
            autoShow: false,
            title: title,
            classes: ['small'],
            content: this.$el
        };

        this.modal = new Modal(modalContents);
        this.modal.show();

    }

    _render(importedTitleCount, patronHasRatedTitles) {
        if (importedTitleCount > 0) {
            this.$el.append(this.titlesImportedTemplate(importedTitleCount));
        }
        else if (!!patronHasRatedTitles) {
            this.$el.append(this.noTitlesImportedTemplate(importedTitleCount));
        }
        else {
            this.$el.append(this.noTitlesRatedTemplate());
        }
    }
}
