const Backbone  = window.Backbone;

import { Modal } from '../../common/modal';
import RtlPanel from './RtlPanel';

const RtlModal = Backbone.View.extend({
    initialize(bus, titleModel, context) {
        this.bus = bus;
        this.context = context;

        this.rtlPanel = new RtlPanel({ titleModel, bus, context });

        let modalContents = {
            autoShow: false,
            title: window.html('recommendToLibraryModal.heading'),
            classes: ['small', 'RecommendModal'],
            content: this.rtlPanel.$el,
            firstFocus: this.rtlPanel.$el.find('.js-first-focus')
        };

        this.modal = new Modal(modalContents);

        this.modal.show();

        this.listenTo(this.bus, 'title:recommend:end', () => { this.modal.close(); });
    }
});

module.exports = RtlModal;
