const { html } = window;

export default class LanguageMismatch extends Backbone.Model {

    get languageText() {
        const languageCodes = this.get('languages').map((language) => language.id && language.id.split('-')[0]);
        const translations = languageCodes.map((code) => code && html(`languages.code-${code}`));
        return html('languageMismatch.inLanguage', {language: translations.join(', ')});
    }

    get areYouSureText() {
        switch (this.get('action')) {
            case 'borrow':
                return html('languageMismatch.areYouSure.borrow');
            case 'hold':
                return html('languageMismatch.areYouSure.hold');
            case 'recommend':
                return html('languageMismatch.areYouSure.recommend');
            case 'wish':
                return html('languageMismatch.areYouSure.wish');
            case 'history':
                return html('languageMismatch.areYouSure.history');
            default:
                return '';
        }
    }

    get actionText() {
        switch (this.get('action')) {
            case 'borrow':
                return html('borrow');
            case 'hold':
                return html('placeHold');
            case 'recommend':
                return html('recommend');
            case 'wish':
                return html('addToWishList');
            case 'history':
                return html('readingHistory.add');
            default:
                return html('confirm');
        }
    }
}
